// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgAwardColor = (props: IconProps) => (
	<Icon
		title={'SvgAwardColor'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<path
					d="M17.997 5.492v.74l-3.654-2.05c-1.383-.771-3.313-.771-4.686 0l-3.655 2.06v-.75C6.002 3.241 7.272 2 9.595 2h4.81c2.323 0 3.592 1.24 3.592 3.492z"
					fill="#DD6B20"
				/>
				<path
					d="M18.028 7.973l-.144-.07-1.404-.78-2.911-1.63c-.888-.501-2.25-.501-3.138 0L7.52 7.112l-1.404.8-.186.09C4.124 9.185 4 9.405 4 11.296v4.522c0 1.891.124 2.111 1.972 3.322l4.459 2.491c.444.26 1.001.37 1.569.37.557 0 1.125-.12 1.569-.37l4.5-2.52C19.887 17.928 20 17.717 20 15.816v-4.522c0-1.89-.124-2.11-1.972-3.322zm-3.148 5.533l-.63.75a.71.71 0 00-.165.47l.062.961c.041.59-.392.89-.96.68l-.919-.36a.943.943 0 00-.526 0l-.919.35c-.568.22-1.001-.09-.96-.68l.062-.96a.71.71 0 00-.165-.47l-.64-.74c-.392-.451-.217-.951.372-1.101l.96-.24a.83.83 0 00.412-.3l.537-.801c.33-.5.857-.5 1.198 0l.537.8c.082.13.268.26.412.3l.96.24c.589.15.764.651.372 1.101z"
					fill="#DD6B20"
				/>
			</svg>
		}
	/>
);

export default SvgAwardColor;
