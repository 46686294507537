// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgCarrentDownSolid = (props: IconProps) => (
	<Icon
		title={'SvgCarrentDownSolid'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 11 7"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<path
					d="M9.489 5.846l.568-.487-.568.489-.039.033V5.88l.039-.034zM5.119.826l4.26 4.971H.86L5.12.827z"
					fill="#2D3748"
					stroke="#2D3748"
					strokeWidth={1.6}
				/>
			</svg>
		}
	/>
);

export default SvgCarrentDownSolid;
