// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgVerify = (props: IconProps) => (
	<Icon
		title={'SvgVerify'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 30 30"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<path
					d="M26.95 13.425l-1.7-1.975c-.325-.375-.588-1.075-.588-1.575V7.75a2.422 2.422 0 00-2.412-2.413h-2.125c-.488 0-1.2-.262-1.575-.587l-1.975-1.7c-.863-.738-2.275-.738-3.15 0l-1.963 1.712c-.375.313-1.087.575-1.575.575H7.725A2.422 2.422 0 005.312 7.75v2.137c0 .488-.262 1.188-.575 1.563L3.05 13.438c-.725.862-.725 2.262 0 3.124l1.687 1.988c.313.375.575 1.075.575 1.563v2.137a2.422 2.422 0 002.413 2.413h2.162c.488 0 1.2.262 1.575.587l1.975 1.7c.863.738 2.275.738 3.15 0l1.975-1.7c.375-.325 1.075-.587 1.575-.587h2.125a2.422 2.422 0 002.413-2.413v-2.125c0-.488.262-1.2.587-1.575l1.7-1.975c.725-.862.725-2.287-.012-3.15zm-6.75-.788l-6.038 6.038a.936.936 0 01-1.325 0L9.812 15.65a.943.943 0 010-1.325.943.943 0 011.325 0l2.363 2.363 5.375-5.375a.943.943 0 011.325 0 .943.943 0 010 1.324z"
					fill="#38A169"
				/>
			</svg>
		}
	/>
);

export default SvgVerify;
