// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgStar = (props: IconProps) => (
	<Icon
		title={'SvgStar'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 95 76"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<path
					d="M45.708 43.307a2.563 2.563 0 00.077 2.4l5.996 10.157a2.516 2.516 0 01-.126 2.75 2.632 2.632 0 01-2.55 1.119L37.32 58.195a2.72 2.72 0 00-1.224.114l-.606.218c-.199.107-.38.247-.53.416l-8.013 8.698c-.4.42-.922.705-1.497.815-1.415.23-2.758-.699-3.026-2.092l-2.02-11.59a2.637 2.637 0 00-1.51-1.907L7.97 48.041a2.539 2.539 0 01-1.522-2.28 2.636 2.636 0 011.377-2.374l10.523-5.596a2.617 2.617 0 001.47-2.055l1.238-11.687c.035-.263.11-.52.227-.758l.144-.227c.063-.153.149-.291.255-.417l.21-.176.324-.292.94-.339a2.684 2.684 0 012.457.617l8.603 8.091a2.665 2.665 0 002.352.678l11.698-2.41a2.715 2.715 0 012.646.924c.62.78.725 1.844.272 2.731l-5.476 10.836z"
					fill="url(#Star_svg__paint0_linear_1504_37473)"
				/>
				<g opacity={0.5} filter="url(#Star_svg__filter0_f_1504_37473)">
					<path
						d="M35.404 37.43c-5.117 0-9.27 4.105-9.27 9.162 0 5.059 4.153 9.163 9.27 9.163 5.125 0 9.268-4.104 9.268-9.163 0-5.057-4.143-9.163-9.268-9.163z"
						fill="#FF3D22"
					/>
				</g>
				<g filter="url(#Star_svg__filter1_b_1504_37473)">
					<path
						d="M72.992 41.6a3.285 3.285 0 00-1.65 2.578L70.326 59.08a3.231 3.231 0 01-2.114 2.801 3.356 3.356 0 01-3.488-.62l-11.28-9.917c-.39-.35-.86-.602-1.369-.743l-.794-.198a2.478 2.478 0 00-.858.065L35.73 53.967a3.569 3.569 0 01-2.162-.198 3.305 3.305 0 01-1.672-4.33l6.232-13.607a3.31 3.31 0 00-.214-3.069l-8.006-12.77a3.194 3.194 0 01.044-3.47 3.407 3.407 0 013.158-1.525l15.097 1.53a3.382 3.382 0 003.028-1.124l9.725-11.415c.226-.252.49-.47.785-.636l.316-.138a2.04 2.04 0 01.567-.26l.349-.036.55-.079 1.234.307a3.355 3.355 0 012.137 2.379l3.212 14.566a3.33 3.33 0 001.984 2.369l14.035 5.707a3.39 3.39 0 012.115 2.835 3.237 3.237 0 01-1.682 3.063L72.992 41.6z"
						fill="#fff"
						fillOpacity={0.31}
					/>
					<path
						d="M70.825 59.112h0l1.017-14.895v-.003a2.782 2.782 0 011.395-2.18l.002-.001 13.562-7.53h.001a3.739 3.739 0 001.94-3.537h0v-.01a3.887 3.887 0 00-2.424-3.25L72.288 22l-.002-.001a2.833 2.833 0 01-1.688-2.014v-.002L67.386 5.416h0l-.002-.007a3.851 3.851 0 00-2.501-2.747l-1.234-.306a.502.502 0 00-.192-.01l-.54.078-.34.035a.505.505 0 00-.086.017 2.55 2.55 0 00-.668.298l-.277.121a.506.506 0 00-.047.024c-.346.196-.653.449-.912.738h0l-.009.01-9.722 11.41-.001.002a2.878 2.878 0 01-2.596.952L33.162 14.5h0-.006a3.91 3.91 0 00-3.623 1.75 3.692 3.692 0 00-.051 4.009h0l8.004 12.766v.002c.485.787.553 1.757.184 2.602l-6.23 13.603h0l-.004.008a3.802 3.802 0 001.92 4.982h0l.014.005a4.07 4.07 0 002.465.227h0l.008-.002 14.693-3.5h0l.01-.002c.209-.054.429-.072.648-.054l.75.186a2.93 2.93 0 011.164.634l.003.002 11.276 9.914.002.002a3.857 3.857 0 004 .712 3.733 3.733 0 002.436-3.235z"
						stroke="url(#Star_svg__paint1_linear_1504_37473)"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
				<defs>
					<linearGradient
						id="Star_svg__paint0_linear_1504_37473"
						x1={9.527}
						y1={39.749}
						x2={34.44}
						y2={75.985}
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#FFDEC7" />
						<stop offset={0.634} stopColor="#FFC75A" />
					</linearGradient>
					<linearGradient
						id="Star_svg__paint1_linear_1504_37473"
						x1={42.743}
						y1={4.496}
						x2={65.888}
						y2={58.645}
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#fff" stopOpacity={0.25} />
						<stop offset={1} stopColor="#fff" stopOpacity={0} />
					</linearGradient>
					<filter
						id="Star_svg__filter0_f_1504_37473"
						x={6.135}
						y={17.429}
						width={58.537}
						height={58.325}
						filterUnits="userSpaceOnUse"
						colorInterpolationFilters="sRGB"
					>
						<feFlood floodOpacity={0} result="BackgroundImageFix" />
						<feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
						<feGaussianBlur
							stdDeviation={10}
							result="effect1_foregroundBlur_1504_37473"
						/>
					</filter>
					<filter
						id="Star_svg__filter1_b_1504_37473"
						x={4.416}
						y={-22.158}
						width={108.836}
						height={109.278}
						filterUnits="userSpaceOnUse"
						colorInterpolationFilters="sRGB"
					>
						<feFlood floodOpacity={0} result="BackgroundImageFix" />
						<feGaussianBlur in="BackgroundImageFix" stdDeviation={12} />
						<feComposite
							in2="SourceAlpha"
							operator="in"
							result="effect1_backgroundBlur_1504_37473"
						/>
						<feBlend
							in="SourceGraphic"
							in2="effect1_backgroundBlur_1504_37473"
							result="shape"
						/>
					</filter>
				</defs>
			</svg>
		}
	/>
);

export default SvgStar;
