// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgMessageTime = (props: IconProps) => (
	<Icon
		title={'SvgMessageTime'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<path
					d="M22 7.43v6c0 1.5-.5 2.75-1.38 3.63-.87.87-2.12 1.37-3.62 1.37v2.13c0 .8-.89 1.28-1.55.84L11 18.43H8.88c.08-.3.12-.61.12-.93 0-1.02-.39-1.96-1.03-2.67A3.944 3.944 0 005 13.5c-1.12 0-2.14.46-2.87 1.21-.09-.4-.13-.83-.13-1.28v-6c0-3 2-5 5-5h10c3 0 5 2 5 5z"
					stroke="#1882FF"
					strokeWidth={1.5}
					strokeMiterlimit={10}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M9 17.5c0 1.2-.53 2.27-1.36 3a4 4 0 01-2.64 1c-2.21 0-4-1.79-4-4 0-1.26.58-2.39 1.5-3.12A3.999 3.999 0 019 17.5z"
					stroke="#1882FF"
					strokeWidth={1.5}
					strokeMiterlimit={10}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M5.25 16.25v1.5L4 18.5M8.5 10.5h7"
					stroke="#1882FF"
					strokeWidth={1.5}
					strokeMiterlimit={10}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		}
	/>
);

export default SvgMessageTime;
