import { atom } from 'jotai';
import { Session } from '../models/Session.model';

const KEY = 'ACCESS_TOKEN';

export const sessionAtomInitialValues: Session = {
	isFetched: false,
	isSignedIn: false
};

const getInitialValues = (): Session => {
	if (typeof window != 'undefined') {
		const session = localStorage.getItem(KEY);
		if (session) return { accessToken: session, isFetched: false, isSignedIn: false };
	}
	return sessionAtomInitialValues;
};

const _sessionAtom = atom<Session>(getInitialValues());

export const sessionAtom = atom<Session, Session>(
	(get) => get(_sessionAtom),
	(get, set, session) => {
		set(_sessionAtom, session);
		if (session.accessToken) {
			localStorage.setItem(KEY, session.accessToken);
		}
	}
);
