// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgLinkedinColor = (props: IconProps) => (
	<Icon
		title={'SvgLinkedinColor'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<path
					d="M21.829 0H2.171A2.171 2.171 0 000 2.171v19.658C0 23.028.972 24 2.171 24h19.658C23.028 24 24 23.028 24 21.829V2.17A2.171 2.171 0 0021.829 0zM7.427 20.723a.632.632 0 01-.632.632h-2.69a.632.632 0 01-.631-.632V9.45c0-.349.282-.631.631-.631h2.69c.349 0 .632.282.632.631v11.274zM5.45 7.755a2.555 2.555 0 110-5.11 2.555 2.555 0 010 5.11zm16.031 13.02c0 .32-.26.58-.58.58h-2.886a.581.581 0 01-.582-.58v-5.289c0-.789.232-3.457-2.061-3.457-1.779 0-2.14 1.826-2.212 2.646v6.1c0 .32-.26.58-.58.58H9.787a.58.58 0 01-.58-.58V9.398c0-.321.26-.581.58-.581h2.791c.321 0 .581.26.581.58v.984c.66-.99 1.64-1.754 3.727-1.754 4.62 0 4.594 4.318 4.594 6.69v5.456z"
					fill="#2D3748"
				/>
			</svg>
		}
	/>
);

export default SvgLinkedinColor;
