// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgCalendar1Outline = (props: IconProps) => (
	<Icon
		title={'SvgCalendar1Outline'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 20 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<path
					d="M17.618 18.333H2.38a.714.714 0 01-.714-.714V6.667a.238.238 0 01.238-.238h16.19a.238.238 0 01.238.238v10.952a.714.714 0 01-.714.714zM2.142 6.905v10.714a.238.238 0 00.238.238h15.238a.238.238 0 00.238-.238V6.905H2.142z"
					fill="#2D3748"
				/>
				<path
					d="M18.094 6.905H1.904a.238.238 0 01-.238-.239V4.047a.714.714 0 01.714-.714h2.381a.238.238 0 010 .476h-2.38a.238.238 0 00-.239.238v2.381h15.714v-2.38a.238.238 0 00-.238-.239h-2.38a.238.238 0 110-.476h2.38a.714.714 0 01.714.714v2.62a.238.238 0 01-.238.238z"
					fill="#2D3748"
				/>
				<path
					d="M13.57 3.81H6.428a.238.238 0 110-.477h7.142a.238.238 0 110 .476z"
					fill="#2D3748"
				/>
				<path
					d="M14.403 5.476a1.074 1.074 0 01-1.071-1.071V2.738a1.072 1.072 0 012.143 0v1.667a1.074 1.074 0 01-1.072 1.071zm0-3.333a.595.595 0 00-.595.595v1.667a.595.595 0 101.19 0V2.738a.595.595 0 00-.595-.595zM5.595 5.476a1.074 1.074 0 01-1.072-1.071V2.738a1.071 1.071 0 112.143 0v1.667a1.074 1.074 0 01-1.071 1.071zm0-3.333A.595.595 0 005 2.738v1.667a.595.595 0 101.19 0V2.738a.595.595 0 00-.595-.595zM10.549 16.52h-1.1a.238.238 0 01-.238-.208l-.095-.714a3.337 3.337 0 01-.77-.32l-.57.436a.239.239 0 01-.313-.021l-.778-.779a.238.238 0 01-.02-.31l.439-.573a3.333 3.333 0 01-.32-.769l-.714-.095a.239.239 0 01-.207-.238V11.83a.238.238 0 01.207-.238l.715-.095c.073-.269.18-.527.319-.77l-.438-.57a.238.238 0 01.021-.313l.779-.778a.238.238 0 01.31-.02l.573.439c.242-.138.5-.245.769-.32l.095-.713a.238.238 0 01.238-.208h1.1a.238.238 0 01.238.208l.096.714c.268.074.527.18.769.319l.569-.438a.238.238 0 01.312.021l.778.779a.238.238 0 01.02.31l-.439.573c.138.242.245.5.32.77l.713.094a.237.237 0 01.208.239v1.1a.238.238 0 01-.207.238l-.715.095a3.332 3.332 0 01-.319.769l.438.569a.238.238 0 01-.021.312l-.779.778a.238.238 0 01-.312.022l-.574-.438c-.242.138-.5.245-.769.319l-.095.714a.239.239 0 01-.233.207zm-.893-.477h.686l.088-.67a.239.239 0 01.186-.201c.327-.072.639-.201.921-.381a.238.238 0 01.271 0l.539.412.476-.477-.405-.535a.238.238 0 010-.272c.18-.282.31-.594.38-.921a.238.238 0 01.203-.186l.67-.088v-.686L13 11.95a.237.237 0 01-.202-.186c-.072-.327-.2-.639-.381-.921a.238.238 0 010-.271l.412-.538-.476-.477-.546.405a.238.238 0 01-.271 0 2.84 2.84 0 00-.921-.38.238.238 0 01-.186-.203l-.088-.67h-.686l-.088.67a.238.238 0 01-.186.202c-.327.072-.639.2-.921.381a.238.238 0 01-.272 0l-.538-.412-.476.476.412.538a.238.238 0 010 .272 2.84 2.84 0 00-.38.921.239.239 0 01-.203.186l-.67.088v.686l.67.088a.238.238 0 01.202.186c.072.327.2.639.381.921a.238.238 0 010 .271l-.412.539.476.476.538-.412a.238.238 0 01.272 0c.282.18.594.31.921.38a.238.238 0 01.186.203l.088.674z"
					fill="#2D3748"
				/>
				<path
					d="M9.999 14.548a2.167 2.167 0 110-4.335 2.167 2.167 0 010 4.335zm0-3.857a1.69 1.69 0 100 3.38 1.69 1.69 0 000-3.38z"
					fill="#2D3748"
				/>
			</svg>
		}
	/>
);

export default SvgCalendar1Outline;
