// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgProfile = (props: IconProps) => (
	<Icon
		title={'SvgProfile'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 76 76"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<g filter="url(#Profile_svg__filter0_b_1525_87)">
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M8.151 23.034c0 8.064 6.599 14.526 14.833 14.526 8.23 0 14.832-6.462 14.832-14.526S31.215 8.508 22.984 8.508C14.75 8.508 8.15 14.97 8.15 23.034zm37.25 30.972c0-7.464-10.327-9.332-22.417-9.332-12.155 0-22.416 1.932-22.416 9.402 0 7.465 10.327 9.332 22.416 9.332 12.156 0 22.416-1.931 22.416-9.402z"
						fill="url(#Profile_svg__paint0_linear_1525_87)"
					/>
				</g>
				<g filter="url(#Profile_svg__filter1_f_1525_87)">
					<path
						d="M27.853 49.462c7.381 0 13.686 1.24 13.686 6.197 0 4.962-6.264 6.245-13.686 6.245-7.382 0-13.686-1.24-13.686-6.198 0-4.961 6.264-6.244 13.686-6.244z"
						fill="#EF5641"
						fillOpacity={0.5}
					/>
				</g>
				<g filter="url(#Profile_svg__filter2_f_1525_87)">
					<path
						d="M26.609 30.384a7.438 7.438 0 01-7.465-7.465 7.438 7.438 0 017.465-7.466 7.44 7.44 0 017.465 7.466 7.44 7.44 0 01-7.465 7.465z"
						fill="#EF5641"
						fillOpacity={0.5}
					/>
				</g>
				<g filter="url(#Profile_svg__filter3_b_1525_87)">
					<mask
						id="Profile_svg__a"
						maskUnits="userSpaceOnUse"
						x={14.432}
						y={2}
						width={61}
						height={74}
						fill="#000"
					>
						<path fill="#fff" d="M14.432 2h61v74h-61z" />
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M25.582 22.05c0 10.575 8.654 19.05 19.453 19.05 10.794 0 19.452-8.475 19.452-19.05C64.487 11.475 55.829 3 45.035 3c-10.799 0-19.452 8.475-19.452 19.05zm48.85 40.62c0-9.79-13.543-12.239-29.398-12.239-15.941 0-29.397 2.534-29.397 12.33C15.637 72.552 29.179 75 45.034 75c15.942 0 29.398-2.534 29.398-12.33z"
						/>
					</mask>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M25.582 22.05c0 10.575 8.654 19.05 19.453 19.05 10.794 0 19.452-8.475 19.452-19.05C64.487 11.475 55.829 3 45.035 3c-10.799 0-19.452 8.475-19.452 19.05zm48.85 40.62c0-9.79-13.543-12.239-29.398-12.239-15.941 0-29.397 2.534-29.397 12.33C15.637 72.552 29.179 75 45.034 75c15.942 0 29.398-2.534 29.398-12.33z"
						fill="#FFAC95"
						fillOpacity={0.4}
					/>
					<path
						d="M45.035 42.1c-11.331 0-20.452-8.903-20.452-20.05h2c0 10.003 8.186 18.05 18.452 18.05v2zm20.452-20.05c0 11.147-9.125 20.05-20.452 20.05v-2c10.262 0 18.452-8.047 18.452-18.05h2zM45.035 2c11.327 0 20.452 8.903 20.452 20.05h-2C63.487 12.047 55.297 4 45.035 4V2zM24.583 22.05C24.582 10.903 33.703 2 45.035 2v2c-10.266 0-18.452 8.046-18.452 18.05h-2zM45.034 49.43c7.95 0 15.437.61 20.968 2.502 2.772.948 5.125 2.242 6.793 4.008 1.69 1.79 2.637 4.023 2.637 6.728h-2c0-2.189-.747-3.932-2.091-5.355-1.366-1.446-3.39-2.6-5.986-3.489-5.204-1.78-12.415-2.394-20.32-2.394v-2zm-30.397 13.33c0-2.704.939-4.943 2.621-6.742 1.66-1.776 4.005-3.082 6.772-4.041 5.522-1.916 13.01-2.547 21.004-2.547v2c-7.947 0-15.157.635-20.349 2.436-2.59.899-4.605 2.063-5.966 3.518-1.339 1.431-2.082 3.183-2.082 5.377h-2zM45.034 76c-7.95 0-15.437-.61-20.968-2.502-2.771-.948-5.124-2.242-6.792-4.008-1.691-1.79-2.637-4.023-2.637-6.728h2c0 2.189.747 3.932 2.09 5.355 1.367 1.446 3.39 2.6 5.987 3.489C29.917 73.386 37.129 74 45.034 74v2zm30.398-13.33c0 2.704-.94 4.943-2.622 6.742-1.66 1.776-4.005 3.082-6.771 4.041C60.517 75.37 53.029 76 45.034 76v-2c7.947 0 15.157-.635 20.35-2.436 2.59-.898 4.605-2.063 5.966-3.518 1.338-1.431 2.082-3.183 2.082-5.377h2z"
						fill="url(#Profile_svg__paint1_linear_1525_87)"
						mask="url(#Profile_svg__a)"
					/>
				</g>
				<defs>
					<filter
						id="Profile_svg__filter0_b_1525_87"
						x={-12.432}
						y={-4.492}
						width={70.832}
						height={80.9}
						filterUnits="userSpaceOnUse"
						colorInterpolationFilters="sRGB"
					>
						<feFlood floodOpacity={0} result="BackgroundImageFix" />
						<feGaussianBlur in="BackgroundImageFix" stdDeviation={6.5} />
						<feComposite
							in2="SourceAlpha"
							operator="in"
							result="effect1_backgroundBlur_1525_87"
						/>
						<feBlend
							in="SourceGraphic"
							in2="effect1_backgroundBlur_1525_87"
							result="shape"
						/>
					</filter>
					<filter
						id="Profile_svg__filter1_f_1525_87"
						x={1.166}
						y={36.462}
						width={53.373}
						height={38.442}
						filterUnits="userSpaceOnUse"
						colorInterpolationFilters="sRGB"
					>
						<feFlood floodOpacity={0} result="BackgroundImageFix" />
						<feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
						<feGaussianBlur
							stdDeviation={6.5}
							result="effect1_foregroundBlur_1525_87"
						/>
					</filter>
					<filter
						id="Profile_svg__filter2_f_1525_87"
						x={4.145}
						y={0.453}
						width={44.93}
						height={44.93}
						filterUnits="userSpaceOnUse"
						colorInterpolationFilters="sRGB"
					>
						<feFlood floodOpacity={0} result="BackgroundImageFix" />
						<feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
						<feGaussianBlur
							stdDeviation={7.5}
							result="effect1_foregroundBlur_1525_87"
						/>
					</filter>
					<filter
						id="Profile_svg__filter3_b_1525_87"
						x={-0.363}
						y={-13}
						width={90.795}
						height={104}
						filterUnits="userSpaceOnUse"
						colorInterpolationFilters="sRGB"
					>
						<feFlood floodOpacity={0} result="BackgroundImageFix" />
						<feGaussianBlur in="BackgroundImageFix" stdDeviation={7.5} />
						<feComposite
							in2="SourceAlpha"
							operator="in"
							result="effect1_backgroundBlur_1525_87"
						/>
						<feBlend
							in="SourceGraphic"
							in2="effect1_backgroundBlur_1525_87"
							result="shape"
						/>
					</filter>
					<linearGradient
						id="Profile_svg__paint0_linear_1525_87"
						x1={19.684}
						y1={39.635}
						x2={61.757}
						y2={56.34}
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#FFA78F" />
						<stop offset={1} stopColor="#F23E2C" />
					</linearGradient>
					<linearGradient
						id="Profile_svg__paint1_linear_1525_87"
						x1={65.073}
						y1={11.388}
						x2={18.092}
						y2={58.902}
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#fff" stopOpacity={0.25} />
						<stop offset={1} stopColor="#fff" stopOpacity={0} />
					</linearGradient>
				</defs>
			</svg>
		}
	/>
);

export default SvgProfile;
