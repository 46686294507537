// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgWallet2Outline = (props: IconProps) => (
	<Icon
		title={'SvgWallet2Outline'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 20 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<path
					d="M14.794 12.895a1.111 1.111 0 01-1.113-1.109c0-.306.125-.584.327-.785a1.11 1.11 0 11.786 1.894zm-.353-.76a.498.498 0 00.847-.349.495.495 0 10-.847.348z"
					fill="#616BFF"
				/>
				<path
					d="M15.053 6.346H2.283v-.679l12.493.015-.03-1.655c0-1.002-.436-1.084-1.376-1.087L3.175 4.636c-.378.086-.61.2-.74.343-.113.124-.152.29-.152.49v10.215c0 .387.156.736.409.988v.002c.252.252.601.407.988.407h11.373a1.399 1.399 0 001.4-1.397v-1.669h.617v1.67c0 .555-.226 1.059-.592 1.423a2.01 2.01 0 01-1.425.59H3.68a2.007 2.007 0 01-1.423-.59 2.006 2.006 0 01-.59-1.424V5.47c0-.347.08-.648.314-.904.215-.236.554-.415 1.077-.532l.016-.003 10.224-1.702c1.607-.153 2.063.59 2.063 1.992l.03 1.703-.338-.293c1.11 0 2.017.898 2.017 2.01v1.654h-.617V7.74c0-.385-.156-.734-.408-.985a1.4 1.4 0 00-.992-.408z"
					fill="#616BFF"
				/>
				<path
					d="M13.065 9.67h4.312a.954.954 0 01.956.956v2.157a.954.954 0 01-.28.675.953.953 0 01-.676.28h-4.312a.953.953 0 01-.676-.28.953.953 0 01-.28-.675v-2.157a.954.954 0 01.956-.956zm4.312.68h-4.312a.275.275 0 00-.276.276v2.157a.275.275 0 00.276.276h4.312a.275.275 0 00.276-.276v-2.157a.275.275 0 00-.276-.276z"
					fill="#616BFF"
				/>
			</svg>
		}
	/>
);

export default SvgWallet2Outline;
