// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgShare = (props: IconProps) => (
	<Icon
		title={'SvgShare'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 13 14"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<path
					d="M3.194 8.887a1.887 1.887 0 100-3.775 1.887 1.887 0 000 3.775zM9.8 13.133a1.887 1.887 0 100-3.775 1.887 1.887 0 000 3.775zM9.8 4.64a1.887 1.887 0 100-3.774 1.887 1.887 0 000 3.775zM8.212 3.773L4.782 5.98M4.781 8.02l3.43 2.205"
					stroke="#2D3748"
					strokeWidth={1.2}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		}
	/>
);
export default SvgShare;
