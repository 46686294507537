// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgStarColor = (props: IconProps) => (
	<Icon
		title={'SvgStarColor'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 18 17"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<path
					stroke="#13c2c2"
					fill="#13c2c2"
					d="M9.3 14c-.3-.2-.5-.2-.8 0l-3.7 2c-.6.3-1.3-.2-1.2-.8l.7-4.3c.1-.2 0-.5-.2-.7L1 7.3C.5 6.8.7 6 1.4 5.9l4.3-.6c.3-.1.4-.2.5-.5l2-3.8C8.5.4 9.3.4 9.6 1l2 3.8c.1.3.3.4.6.5l4.3.6c.6.1.8.9.4 1.4l-3.1 2.9c-.2.2-.3.5-.2.7l.7 4.2c.1.7-.6 1.2-1.2.9l-3.8-2z"
				/>
			</svg>
		}
	/>
);

export default SvgStarColor;
