import axios from 'axios';

export const instance = axios.create({
	baseURL: process.env.NEXT_PUBLIC_API_ENDPOINT,
	headers: {
		'Content-Type': 'application/json'
	}
});

export const setToken = (accessToken: string) => {
	instance.defaults.headers.common = {
		Authorization: `Bearer ${accessToken}`,
		...instance.defaults.headers.common
	};
};
