// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgEmotionOkayGray = (props: IconProps) => (
	<Icon
		title={'SvgEmotionOkayGray'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 72 72"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<rect width={72} height={72} rx={36} fill="#CBD5E0" />
				<path
					d="M21.803 34.971a4.042 4.042 0 100-8.084 4.042 4.042 0 000 8.084zM50.117 34.971a4.042 4.042 0 100-8.084 4.042 4.042 0 000 8.084zM49.514 50.078L22.748 52.42c-.312.027-.623-.62-.695-1.445-.073-.826.122-1.517.434-1.544L49.25 47.09c.313-.028.624.619.696 1.444.075.826-.12 1.517-.431 1.544z"
					fill="#fff"
				/>
			</svg>
		}
	/>
);

export default SvgEmotionOkayGray;
