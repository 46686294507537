// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgAstronautColored = (props: IconProps) => (
	<Icon
		title={'SvgAstronautColored'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 40 40"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<path
					d="M40 20a19.93 19.93 0 01-5.33 13.594A20.046 20.046 0 0128.728 38 19.882 19.882 0 0120 40a19.882 19.882 0 01-8.728-2 20.063 20.063 0 01-5.942-4.406A19.934 19.934 0 010 19.999C0 8.956 8.955 0 20 0c11.046 0 20 8.955 20 20z"
					fill="#A0AEC0"
				/>
				<path
					d="M6.56 13.776l2.89-.32-2.89-.32-.32-2.89-.32 2.89-2.89.32 2.89.32.32 2.89.32-2.89z"
					fill="#fff"
				/>
				<path
					d="M30.732 16.553H9.267a2.062 2.062 0 00-2.062 2.062v9.408c0 1.139.923 2.062 2.062 2.062h21.465a2.062 2.062 0 002.062-2.062v-9.408a2.062 2.062 0 00-2.062-2.062z"
					fill="#CBD5E0"
				/>
				<path
					d="M34.375 26.611l1.847-.204-1.847-.204-.204-1.848-.205 1.848-1.847.204 1.847.204.205 1.847.204-1.847z"
					fill="#fff"
				/>
				<path
					d="M34.67 30.393v2.373h-2.768v-2.938h2.74c.019.186.028.374.028.565z"
					fill="#444CFF"
				/>
				<path
					d="M12.88 12.764A8.935 8.935 0 0120 9.236a8.936 8.936 0 017.122 3.528 8.91 8.91 0 011.816 5.919 8.92 8.92 0 01-2.822 6.041A8.915 8.915 0 0120 27.14a8.92 8.92 0 01-6.116-2.415 8.93 8.93 0 01-2.835-6.537 8.91 8.91 0 011.83-5.424zm14.055 5.783c0-3.726-3.104-5.368-6.935-5.368-3.83 0-6.935 1.642-6.935 5.368 0 3.727 3.105 6.749 6.935 6.749 3.83 0 6.935-3.021 6.935-6.749z"
					fill="#F1F5F9"
				/>
				<path
					d="M23.123 26.36a8.92 8.92 0 01-6.116-2.416 8.931 8.931 0 01-2.835-6.537 8.91 8.91 0 011.83-5.424 8.981 8.981 0 013.42-2.728 8.936 8.936 0 00-6.543 3.509 8.91 8.91 0 00-1.817 5.918 8.93 8.93 0 002.822 6.043A8.92 8.92 0 0020 27.14a8.919 8.919 0 003.704-.8c-.192.012-.386.02-.581.02z"
					fill="#E2E8F0"
				/>
				<path
					d="M20 25.643c-4.015 0-7.282-3.183-7.282-7.096 0-3.12 2.095-5.169 5.748-5.623.49-.06 1.006-.092 1.534-.092 2.074 0 3.839.467 5.103 1.35 1.425.995 2.179 2.504 2.179 4.365 0 2.747-1.65 5.272-4.206 6.432a7.367 7.367 0 01-3.076.664zm0-12.117c-.5 0-.987.03-1.448.087-4.473.556-5.14 3.33-5.14 4.934 0 3.53 2.955 6.402 6.588 6.402.975 0 1.913-.203 2.789-.601 2.308-1.049 3.799-3.325 3.799-5.8 0-1.648-.634-2.925-1.883-3.798-1.147-.8-2.774-1.224-4.705-1.224z"
					fill="#CBD5E0"
				/>
				<path
					d="M26.936 18.547c0 2.707-1.638 5.043-4.003 6.116a7.058 7.058 0 01-2.932.633c-3.83 0-6.936-3.021-6.936-6.749 0-3.229 2.331-4.891 5.444-5.278.481-.06.98-.09 1.491-.09 3.831 0 6.936 1.642 6.936 5.368z"
					fill="#13C2C2"
				/>
				<path
					d="M26.936 18.547c0 2.707-1.638 5.043-4.003 6.116a6.636 6.636 0 01-.59.026c-3.83 0-6.936-3.022-6.936-6.75 0-2.346 1.233-3.867 3.102-4.67.48-.06.98-.09 1.491-.09 3.83 0 6.936 1.642 6.936 5.368z"
					fill="#5CDBD3"
				/>
				<path
					d="M28.729 24.725v.03A10.745 10.745 0 0120 29.222c-3.593 0-6.774-1.76-8.729-4.464v-.033h2.612A8.92 8.92 0 0020 27.14a8.915 8.915 0 006.116-2.416v.001h2.613z"
					fill="#CBD5E0"
				/>
				<path
					d="M11.273 24.758v13.241a20.065 20.065 0 01-5.942-4.405v-3.201a5.666 5.666 0 015.667-5.668h.25c.009.011.016.023.025.033zM34.67 32.766v.828a20.049 20.049 0 01-5.94 4.405V24.755a.428.428 0 00.022-.03h.25a5.67 5.67 0 015.64 5.103h-2.74v2.938h2.769z"
					fill="#E2E8F0"
				/>
				<path
					d="M20 29.22V40c-3.13 0-6.09-.72-8.727-2V24.756A10.746 10.746 0 0020 29.22zM20 29.222V40c3.13 0 6.091-.72 8.728-2V24.754A10.746 10.746 0 0120 29.222z"
					fill="#F1F5F9"
				/>
				<path
					d="M24.54 20.279c.718 0 1.3-.971 1.3-2.169 0-1.197-.582-2.168-1.3-2.168-.72 0-1.302.97-1.302 2.168 0 1.198.583 2.169 1.302 2.169z"
					fill="#87E8DE"
				/>
				<path
					d="M35.852 16.424l2.89-.32-2.89-.32-.32-2.89-.32 2.89-2.89.32 2.89.32.32 2.89.32-2.89zM34.499 22.447l.572.573.573-.573-.573-.572-.572.572zM3.265 25.448l.573.573.572-.573-.572-.572-.573.572zM3.641 19.59l.573.572.572-.573-.572-.572-.573.572zM24.547 3.306l.572.572.573-.572-.573-.573-.572.573zM15.555 4.7l.572.572.572-.572-.572-.572-.572.572z"
					fill="#fff"
				/>
				<path
					d="M20.737 28.524v11.462a18.988 18.988 0 01-1.474 0V28.524h1.474z"
					fill="#CBD5E0"
				/>
				<path
					d="M24.169 31.56H15.83a1.21 1.21 0 00-1.209 1.21v3.566c0 .668.542 1.21 1.21 1.21h8.337a1.21 1.21 0 001.21-1.21v-3.567a1.21 1.21 0 00-1.21-1.21z"
					fill="#CBD5E0"
				/>
				<path
					d="M15.832 36.417a.082.082 0 01-.082-.081v-3.567c0-.045.037-.082.082-.082h8.337c.045 0 .082.037.082.082v3.567a.082.082 0 01-.082.081h-8.337z"
					fill="#A0AEC0"
				/>
				<path
					d="M17.298 35.579a.954.954 0 100-1.909.954.954 0 000 1.909zM22.705 35.579a.954.954 0 100-1.909.954.954 0 000 1.909zM20.001 35.579a.954.954 0 100-1.909.954.954 0 000 1.909zM5.331 30.393v2.373H8.1v-2.938h-2.74a5.784 5.784 0 00-.028.565z"
					fill="#444CFF"
				/>
			</svg>
		}
	/>
);

export default SvgAstronautColored;
