// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgEmotionGoodGray = (props: IconProps) => (
	<Icon
		title={'SvgEmotionGoodGray'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 72 72"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<rect width={72} height={72} rx={36} fill="#CBD5E0" />
				<path
					d="M20.383 33.78c1.725 0 3.123-2.357 3.123-5.265 0-2.908-1.398-5.265-3.123-5.265-1.725 0-3.123 2.357-3.123 5.265 0 2.908 1.398 5.265 3.123 5.265zM51.617 33.78c1.725 0 3.123-2.357 3.123-5.265 0-2.908-1.398-5.265-3.123-5.265-1.725 0-3.123 2.357-3.123 5.265 0 2.908 1.398 5.265 3.123 5.265zM46.722 40.457c2.828 0 4.662 2.974 3.4 5.507-2.376 4.773-7.737 8.102-13.979 8.102-6.237 0-11.603-3.33-13.979-8.102-1.258-2.533.577-5.507 3.4-5.507h21.158z"
					fill="#fff"
				/>
			</svg>
		}
	/>
);

export default SvgEmotionGoodGray;
