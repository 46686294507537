// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgEmotionTerribleGray = (props: IconProps) => (
	<Icon
		title={'SvgEmotionTerribleGray'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 72 72"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<rect width={72} height={72} rx={36} fill="#CBD5E0" />
				<path
					d="M46.234 50.537c.59.216 1.183-.282 1.095-.902-.734-5.168-5.691-9.166-11.695-9.166-6 0-10.957 3.994-11.692 9.162-.088.62.506 1.117 1.096.902l5.656-2.09a14.171 14.171 0 019.774-.017l5.766 2.111zM23.67 34.823c1.331 0 2.41-1.818 2.41-4.06 0-2.242-1.079-4.06-2.41-4.06-1.331 0-2.41 1.818-2.41 4.06 0 2.242 1.079 4.06 2.41 4.06zM47.765 34.823c1.331 0 2.41-1.818 2.41-4.06 0-2.242-1.079-4.06-2.41-4.06-1.331 0-2.41 1.818-2.41 4.06 0 2.242 1.079 4.06 2.41 4.06z"
					fill="#fff"
				/>
				<path
					d="M26.4 27.814c-1.447-1.108-4.873-3.14-10.45-3.154a.842.842 0 01-.713-.37c-.29-.435-.545-1.165.435-1.736 0 0 2.728-1.615 5.956-.374 0 0 3.563 1.781 4.992 4.064.062.101.106.211.132.33l.207.87c.075.33-.294.573-.558.37zM44.866 27.814c1.447-1.108 4.874-3.14 10.451-3.154a.842.842 0 00.713-.37c.29-.435.545-1.165-.436-1.736 0 0-2.727-1.615-5.955-.374 0 0-3.563 1.781-4.993 4.064a1.081 1.081 0 00-.132.33l-.206.87c-.075.33.294.573.558.37z"
					fill="#fff"
				/>
			</svg>
		}
	/>
);

export default SvgEmotionTerribleGray;
