// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgSystemVoucherCustomApplied = (props: IconProps) => (
	<Icon
		title={'SvgSystemVoucherCustomApplied'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 20 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.633 12.554a1.718 1.718 0 01-1.724-1.712c0-.945.772-1.711 1.724-1.711.953 0 1.725.766 1.725 1.711 0 .945-.772 1.712-1.725 1.712zm-6.608-.13c-.258 0-.516-.098-.712-.294a.995.995 0 010-1.414l6.899-6.846a1.013 1.013 0 011.425 0 .994.994 0 010 1.414l-6.9 6.846a1.008 1.008 0 01-.712.293zm.431-9.014c.953 0 1.725.766 1.725 1.712 0 .945-.772 1.711-1.725 1.711a1.718 1.718 0 01-1.724-1.711c0-.946.772-1.712 1.724-1.712zm13 2.617L16.767 1.5A3.114 3.114 0 0014.085 0H3.095C1.385 0 0 1.343 0 3v10c0 1.657 1.385 3 3.095 3h10.99a3.114 3.114 0 002.68-1.502l2.692-4.525a3.857 3.857 0 000-3.947z"
					fill="#38A169"
				/>
			</svg>
		}
	/>
);

export default SvgSystemVoucherCustomApplied;
