// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgGroupPeopleSolid = (props: IconProps) => (
	<Icon
		title={'SvgGroupPeopleSolid'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<path
					opacity={0.4}
					d="M17.53 7.77a.739.739 0 00-.21 0 2.874 2.874 0 01-2.78-2.88C14.54 3.3 15.83 2 17.43 2c1.59 0 2.89 1.29 2.89 2.89a2.89 2.89 0 01-2.79 2.88zM20.79 14.7c-1.12.75-2.69 1.03-4.14.84.38-.82.58-1.73.59-2.69 0-1-.22-1.95-.64-2.78 1.48-.2 3.05.08 4.18.83 1.58 1.04 1.58 2.75.01 3.8zM6.44 7.77c.07-.01.14-.01.21 0a2.874 2.874 0 002.78-2.88C9.43 3.3 8.14 2 6.54 2 4.95 2 3.65 3.29 3.65 4.89a2.89 2.89 0 002.79 2.88zM6.55 12.85c0 .97.21 1.89.59 2.72-1.41.15-2.88-.15-3.96-.86-1.58-1.05-1.58-2.76 0-3.81 1.07-.72 2.58-1.01 4-.85-.41.84-.63 1.79-.63 2.8z"
					fill="currentColor"
				/>
				<path
					d="M12.12 15.87a1.13 1.13 0 00-.26 0 3.425 3.425 0 01-3.31-3.43c0-1.9 1.53-3.44 3.44-3.44 1.9 0 3.44 1.54 3.44 3.44 0 1.86-1.46 3.37-3.31 3.43zM8.87 17.94c-1.51 1.01-1.51 2.67 0 3.67 1.72 1.15 4.54 1.15 6.26 0 1.51-1.01 1.51-2.67 0-3.67-1.71-1.15-4.53-1.15-6.26 0z"
					fill="currentColor"
				/>
			</svg>
		}
	/>
);

export default SvgGroupPeopleSolid;
