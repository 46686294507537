import React, { Fragment, KeyboardEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Avatar, Button, KindButton, Label2, Label3 } from '@pixiepkg/react';
import styles from './gpt.module.scss';
import classnames from 'classnames';
import { CloseOutline } from '@pixiepkg/icon';
import SvgGpt from '@components/Icon/components/Gpt';
import { Transition } from '@headlessui/react';
import { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { IChatResponse, OAuthProvider, SignInOAuthPayload } from '@api/PayloadTypes';
import { api } from '@api';
import { toast } from 'react-toastify';
import {
	ReactFacebookFailureResponse,
	ReactFacebookLoginInfo
} from 'react-facebook-login/dist/facebook-login-render-props';
import { useAtom } from 'jotai';
import { sessionAtom } from '@shared/stores/session.store';
import { GoogleLoginButton } from '@containers/LoginModal/components/GoogleLoginButton';
import { FacebookLoginButton } from '@containers/LoginModal/components/FacebookLoginButton';
import { Player } from '@lottiefiles/react-lottie-player';
import gptLoading from './gptLoading.json';
import { v4 } from 'uuid';
import { SCREEN_SIZES, useWindowSize } from '@shared/hooks/useWindownSize';
import { gptChatBox } from '@shared/stores/gptChatBox';
import { TypeAnimation } from 'react-type-animation';
import { useRouter } from 'next/router';

export const ChatGPTMessage = () => {
	const [input, setInput] = useState('');
	const [chatGptModel, setChatGptModel] = useAtom(gptChatBox);
	const [loading, setLoading] = useState(false);
	const bottomRef = useRef<HTMLDivElement>(null);
	const [session, setSession] = useAtom(sessionAtom);
	const [requiredPhone, setRequiredPhone] = useState(false);
	const inputRef = useRef<HTMLInputElement>(null);
	const router = useRouter();
	const [showSvgGpt, setShowSvgGpt] = useState(true);
	const cache = useRef<{
		message: string;
		first: boolean;
		stream: boolean;
		stream_id: string | null;
	}>({
		message: '',
		first: true,
		stream: false,
		stream_id: null
	});
	const { width } = useWindowSize();
	const isMobile = width <= SCREEN_SIZES.xs;

	const [chatList, setChatList] = useState<IChatResponse[]>([
		{
			role: 'GPT',
			text: 'Hello!! Hỏi lẹ đáp nhanh cùng bọn mình nè!!',
			type: 'old',
			accountID: '',
			createdAt: 0,
			id: '0',
			streaming: false
		}
	]);

	const scrollToBottom = () => {
		if (bottomRef.current) {
			bottomRef.current.scrollIntoView(false);
		}
	};

	const handleMessageClick = () => {
		setChatGptModel({ isOpen: true });
		// setOpen(true);
	};

	const handleClose = () => {
		setChatGptModel({ isOpen: false });
	};

	const onGoogleCallback = (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
		setLoading(true);
		response = response as GoogleLoginResponse;
		(async () => {
			const payload: SignInOAuthPayload = {
				oauthToken: response.tokenId,
				type: OAuthProvider.Google
			};
			try {
				api.removeAuthHeader();
				const token = await api
					.signInOAuth(payload)
					.then((res) => {
						return res.data.data;
					})
					.then((data) => data.token);
				api.setToken(token);
				const user = await api.getAuthenticatedProfile().then((res) => res.data);
				setSession({
					...session,
					isSignedIn: true,
					isFetched: true,
					accessToken: token,
					user
				});
			} catch (error) {
				toast.warn('Can not login by google, please try again later!');
			}
			setLoading(false);
		})();
	};

	const onFacebookCallback = (
		response: ReactFacebookLoginInfo | ReactFacebookFailureResponse
	) => {
		setLoading(true);
		const fbRes = response as ReactFacebookLoginInfo;
		if (fbRes.accessToken) {
			(async () => {
				const payload: SignInOAuthPayload = {
					oauthToken: fbRes.accessToken,
					type: OAuthProvider.Facebook
				};
				try {
					api.removeAuthHeader();
					const token = await api
						.signInOAuth(payload)
						.then((res) => {
							return res.data.data;
						})
						.then((data) => data.token);
					api.setToken(token);
					const user = await api.getAuthenticatedProfile().then((res) => res.data);
					setSession({
						...session,
						isSignedIn: true,
						isFetched: true,
						accessToken: token,
						user
					});
				} catch (error) {
					toast.warn('Can not login by facebook, please try again later!');
					setLoading(false);
				}
				setLoading(false);
			})();
		} else if ((fbRes as any).status && (fbRes as any).status !== 'unknown') {
			toast.warn('Can not login by facebook, please try again later!');
		}
		setLoading(false);
	};

	useEffect(() => {
		if (!session.isSignedIn) {
			setChatList([
				{
					role: 'GPT',
					text: 'Hello!! Hỏi lẹ đáp nhanh cùng bọn mình nè!!',
					type: 'old',
					id: '0',
					streaming: false
				}
			]);
			cache.current.first = true;
		}
		if (chatGptModel.isOpen && cache.current.first && session.isSignedIn) {
			api.getChat().then((res) => {
				const sorted = res.data.messages
					.sort((a: any, b: any) => a.createdAt - b.createdAt)
					.map((item: any) => ({
						...item,
						type: 'old'
					}));

				setChatList(sorted);
				cache.current.first = false;
			});
		}
	}, [chatGptModel.isOpen, session.isSignedIn]);

	useEffect(() => {
		setTimeout(() => {
			scrollToBottom();
			if (inputRef.current) inputRef.current?.focus();
		}, 0);
	}, [chatGptModel.isOpen, session, chatList]);

	useEffect(() => {
		let intervalFunc: string | number | NodeJS.Timeout | undefined;
		if (cache.current.stream && cache.current.stream_id) {
			intervalFunc = setInterval(async () => {
				api.streamChat<IChatResponse>(cache.current.stream_id as string).then((res) => {
					setChatList((prevState) => {
						return prevState.map((chat) => {
							if (chat.id === res.data.id) {
								chat.streaming = res.data.streaming;
								chat.text = [res.data.text as string];
							}
							return chat;
						});
					});
					if (res.data.streaming) {
						cache.current.stream = res.data.streaming;
						cache.current.stream_id = res.data.id;
					} else {
						cache.current.stream = false;
						cache.current.stream_id = null;
						clearInterval(intervalFunc);
					}
				});
			}, 1000);
		}

		return () => {
			clearInterval(intervalFunc);
		};
	}, [cache.current.stream]);

	// Hide Icon GPT when in Meeting
	useEffect(() => {
		const currentPath = router.pathname;

		if (currentPath.includes('/meeting')) {
			setShowSvgGpt(false);
		} else {
			setShowSvgGpt(true);
		}
	}, [router.pathname]);

	const handleMessage = (isRetry = false) => {
		setLoading(true);

		setInput('');
		if (!isRetry)
			setChatList((prevState) => [
				...prevState,
				{
					role: 'user',
					text: input,
					type: 'new',
					id: '0',
					createdAt: 0,
					accountID: '0',
					streaming: false
				}
			]);

		api.sendChat<IChatResponse>({ message: isRetry ? cache.current.message : input })
			.then((res) => {
				cache.current.stream = res.data.streaming;
				cache.current.stream_id = res.data.id;
				setChatList((prevState) => [
					...prevState,
					{ ...res.data, text: [res.data.text as string], type: 'new' }
				]);
				cache.current.message = '';
			})
			.catch((err) => {
				if (err === 'required_phone') {
					setRequiredPhone(true);
					cache.current.message = input;
				} else if (err.includes('429')) {
					toast.error('Tin nhắn gửi quá nhanh. Vui lòng thử lại sau 20s', {
						position: 'top-right'
					});
				}
			})
			.finally(() => {
				setLoading(false);
				setTimeout(() => {
					scrollToBottom();
					if (inputRef.current) inputRef.current?.focus();
				}, 0);
			});
	};

	const handleUpdateMessage = () => {
		setLoading(true);

		setInput('');
		api.updatePhoneNumber(input)
			.then(() => {
				setRequiredPhone(false);
				setInput(cache.current.message);
				handleMessage(true);
			})
			.catch((err) => {
				if (err === 'required_phone') {
					setRequiredPhone(true);
				}
			})
			.finally(() => {
				setLoading(false);
				setTimeout(() => {
					scrollToBottom();
					if (inputRef.current) inputRef.current?.focus();
				}, 0);
			});
	};

	const handleOnKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			if (!requiredPhone) handleMessage(false);
			else handleUpdateMessage();
		}
	};

	const animationDesktop = {
		enter: 'transform transition ease-in-out duration-500 sm:duration-700',
		enterFrom: 'translate-x-full translate-y-full opacity-0 scale-50',
		enterTo: 'translate-x-100 translate-y-100 opacity-100 scale-100',
		leave: 'transform transition ease-in-out duration-500 sm:duration-700',
		leaveFrom: 'translate-x-100 translate-y-100 opacity-100 scale-100',
		leaveTo: 'translate-x-full translate-y-full opacity-0 scale-50'
	};

	const animationMobile = {
		enter: 'transform transition ease-in-out duration-500 sm:duration-700',
		enterFrom: 'xs:translate-y-full opacity-0 scale-80',
		enterTo: 'xs:translate-y-100 opacity-100 scale-100',
		leave: 'transform transition ease-in-out duration-500',
		leaveFrom: 'xs:translate-y-100 opacity-100 scale-100',
		leaveTo: 'xs:translate-y-full opacity-0 scale-80'
	};
	const animation = useMemo(() => {
		if (isMobile) return animationMobile;
		else return animationDesktop;
	}, [isMobile]);

	return (
		<>
			{/* This is button */}
			<Transition
				show={!chatGptModel.isOpen}
				enter="transition-opacity duration-500"
				enterFrom="opacity-0"
				enterTo="opacity-100"
				leave="transition-opacity duration-500"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
			>
				<div
					className={'fixed bottom-28 right-6 z-[9999] cursor-pointer'}
					onClick={handleMessageClick}
				>
					{showSvgGpt && <SvgGpt size={56} />}
					{showSvgGpt && (
						<div
							className={
								'absolute -right-1 -top-2 rounded-2xl bg-red p-1.5 text-xs font-bold text-white'
							}
						>
							<span>Mới</span>
						</div>
					)}
				</div>
			</Transition>
			{/* This is chat block */}

			<Transition as={Fragment} {...animation} show={chatGptModel.isOpen}>
				<div
					className={classnames(
						isMobile
							? 'fixed bottom-0 right-0 z-[999] flex h-full w-full flex-col rounded-xl bg-white'
							: 'fixed bottom-6 right-6 z-[999] rounded-xl bg-white',
						styles.shadow
					)}
				>
					<div className="relative flex items-center justify-between border-b border-gray-200 px-6 py-4">
						<Label2>Hỏi nhanh đáp gọn cùng Pixie</Label2>
						<Button
							kind={KindButton.SECONDARY}
							style={{ padding: '8px', height: 'auto' }}
							onClick={handleClose}
						>
							<CloseOutline />
						</Button>
					</div>
					{session?.accessToken && session.isFetched && session.isSignedIn ? (
						<>
							<div
								className={classnames(
									'scroll-smooth flex h-[550px] w-[500px] items-end justify-end bg-white xs:w-full xs:flex-1'
								)}
							>
								<ul className="h-full min-h-full w-full space-y-2 overflow-y-auto">
									{chatList.map((chat) => {
										if (chat.role === 'assistant') {
											return (
												<li
													key={v4()}
													className="flex bg-gray-100 px-6 py-4"
												>
													<div className="relative text-gray-700 ">
														<div
															className={
																'flex items-start justify-center gap-4'
															}
														>
															<SvgGpt size={40} />

															{chat.type === 'old' ? (
																<Label3
																	semiBold
																	color={'#2D3748'}
																	className={'self-center'}
																>
																	{chat.text}
																</Label3>
															) : (
																<>
																	<Label3
																		semiBold
																		color={'#2D3748'}
																		className={'self-center'}
																	>
																		{chat.text[0]}{' '}
																		{cache.current.stream &&
																			cache.current
																				.stream_id ===
																				chat.id && (
																				<TypeAnimation
																					sequence={[
																						'.....'
																					]}
																					wrapper="span"
																					speed={5}
																					className={
																						'self-center'
																					}
																					style={{
																						fontSize:
																							'16px',
																						lineHeight:
																							'22px',
																						fontWeight: 600,
																						display:
																							'inline-block',
																						color: '#2D3748'
																					}}
																				/>
																			)}
																	</Label3>
																</>
															)}
														</div>
													</div>
												</li>
											);
										} else {
											return (
												<li key={v4()} className="flex px-6 py-4">
													<div className="relativ text-gray-700 ">
														<div
															className={
																'flex items-start justify-center gap-4'
															}
														>
															<Avatar
																src={session.user?.avatar || ''}
																width={40}
																height={40}
															/>
															<Label3
																semiBold
																color={'#2D3748'}
																className={'self-center'}
															>
																{chat.text}
															</Label3>
														</div>
													</div>
												</li>
											);
										}
									})}

									{loading && (
										<li className="flex items-center justify-center">
											<Player
												autoplay
												loop
												src={gptLoading}
												style={{
													height: 95,
													width: '100%'
												}}
											/>
										</li>
									)}
									<div ref={bottomRef} />
								</ul>
							</div>
							<div
								className={classnames(
									requiredPhone
										? 'mx-6 my-4 flex flex-col rounded-lg bg-brand-50'
										: ''
								)}
							>
								<div
									className={classnames(
										requiredPhone
											? 'flex flex-col px-5 py-4'
											: 'flex flex-col px-6 py-4'
									)}
								>
									{requiredPhone && (
										<Label3 semiBold>
											Nhập số điện thoại để hỏi đáp không giới hạn
										</Label3>
									)}
									<div className={'flex w-full items-center gap-2'}>
										<input
											ref={inputRef}
											type={requiredPhone ? 'number' : 'text'}
											className={
												'text-md flex-1 rounded-lg border border-gray-100 bg-gray-200 px-4 py-3 font-[500] text-gray-500'
											}
											placeholder={
												requiredPhone
													? 'Nhập số điện thoại tại đây'
													: 'Nhập câu hỏi tại đây'
											}
											onKeyDown={requiredPhone ? () => {} : handleOnKeyDown}
											onChange={(e) => setInput(e.target.value)}
											value={input}
											disabled={loading}
										/>
										<Button
											disabled={loading || cache.current.stream}
											kind={KindButton.BRAND}
											className={'h-full px-6 py-2.5'}
											onClick={
												requiredPhone
													? () => handleUpdateMessage()
													: () => handleMessage(false)
											}
										>
											Gửi
										</Button>
									</div>
								</div>
							</div>
						</>
					) : (
						<div className="scroll-smooth h-[550px] w-[500px] overflow-y-auto xs:h-full xs:w-full">
							<div className={'absolute bottom-0 left-0 right-0'}>
								<ul className="bg-gray-100 px-6 py-4">
									<li className="flex">
										<div className="relative text-gray-700 ">
											<div
												className={'flex items-center justify-center gap-4'}
											>
												<SvgGpt size={40} />
												<Label3 semiBold color={'#2D3748'}>
													Hello!! Hỏi lẹ đáp nhanh cùng bọn mình nè!!
												</Label3>
											</div>
										</div>
									</li>
								</ul>
								<div className={'m-6 rounded-lg bg-brand-50 px-6 pb-5 text-center'}>
									<Label3 className={'p-4'} semiBold>
										Đăng nhập để trải nghiệm chat GPT
									</Label3>
									<div>
										<GoogleLoginButton callback={onGoogleCallback} />
									</div>
									<div className="mt-2">
										<FacebookLoginButton callback={onFacebookCallback} />
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</Transition>
		</>
	);
};
