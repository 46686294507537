// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgBookingPayment2User = (props: IconProps) => (
	<Icon
		title={'SvgBookingPayment2User'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 72 72"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M6.328 0h59.344A6.335 6.335 0 0172 6.328v16.875a6.335 6.335 0 01-6.328 6.328h-7.564l-3.601 3.601a2.109 2.109 0 01-2.983 0l-3.6-3.6H24.075l-3.6 3.6a2.109 2.109 0 01-2.983 0l-3.6-3.6H6.327A6.335 6.335 0 010 23.202V6.328A6.335 6.335 0 016.328 0zm4.219 21.094h16.875c1.166 0 2.11-.944 2.11-2.11s-.944-2.109-2.11-2.109H10.547c-1.166 0-2.11.944-2.11 2.11 0 1.165.944 2.109 2.11 2.109zm0-8.438h16.875c1.166 0 2.11-.943 2.11-2.11 0-1.165-.944-2.108-2.11-2.108H10.547a2.108 2.108 0 100 4.219zm34.031 8.438h16.875c1.166 0 2.11-.944 2.11-2.11s-.944-2.109-2.11-2.109H44.578c-1.166 0-2.11.944-2.11 2.11 0 1.165.944 2.109 2.11 2.109zm0-8.438h16.875c1.166 0 2.11-.943 2.11-2.11 0-1.165-.944-2.108-2.11-2.108H44.578a2.108 2.108 0 100 4.219zM19.125 55.125a8.438 8.438 0 100-16.875 8.438 8.438 0 000 16.875zm0 0c8.14 0 14.766 6.625 14.766 14.766 0 1.165-.944 2.109-2.11 2.109H6.47a2.108 2.108 0 01-2.11-2.11c0-8.14 6.625-14.765 14.766-14.765zm42.188-8.438a8.438 8.438 0 11-16.876 0 8.438 8.438 0 0116.876 0zm-8.438 8.438c8.14 0 14.766 6.625 14.766 14.766 0 1.165-.944 2.109-2.11 2.109H40.22a2.108 2.108 0 01-2.11-2.11c0-8.14 6.625-14.765 14.766-14.765z"
					fill="url(#booking-payment-2user_svg__paint0_linear)"
				/>
				<defs>
					<linearGradient
						id="booking-payment-2user_svg__paint0_linear"
						x1={70.611}
						y1={39.316}
						x2={-0.605}
						y2={39.239}
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#616BFF" />
						<stop offset={1} stopColor="#0BF0FF" />
					</linearGradient>
				</defs>
			</svg>
		}
	/>
);

export default SvgBookingPayment2User;
