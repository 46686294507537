// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgEmotionGreatColor = (props: IconProps) => (
	<Icon
		title={'SvgEmotionGreatColor'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 72 72"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<rect width={72} height={72} rx={36} fill="#FFE5A5" />
				<path
					d="M9.751 42.21a3.25 3.25 0 100-6.499 3.25 3.25 0 000 6.5zM62.251 42.21a3.25 3.25 0 100-6.499 3.25 3.25 0 000 6.5zM44.61 41.39c2.402 0 3.955 2.525 2.89 4.676-2.014 4.051-6.566 6.88-11.862 6.88s-9.849-2.829-11.863-6.88c-1.07-2.15.488-4.675 2.89-4.675H44.61zM17.194 31.779a14.858 14.858 0 01-1.1-1.003c-1.5-1.522-3.299-4.03-2.569-6.285.563-1.747 2.617-2.261 4.258-2.151.897.061 1.773.32 2.582.712.29.137.651.304.972.515a.471.471 0 00.726-.374c.075-1.504.607-3.08 1.962-3.81 1.473-.795 3.096-.268 4.183.911 1.746 1.896 1.566 4.509.844 6.805a28.985 28.985 0 01-1.93 4.684c-.423.823-.788 1.724-1.408 2.428-1.082 1.227-2.662.92-3.994.321-1.592-.725-3.158-1.627-4.526-2.753zM54.072 31.779c.383-.317.752-.651 1.1-1.003 1.5-1.522 3.299-4.03 2.569-6.285-.563-1.747-2.618-2.261-4.258-2.151-.897.061-1.773.32-2.582.712-.29.137-.651.304-.972.515a.47.47 0 01-.726-.374c-.075-1.504-.607-3.08-1.962-3.81-1.473-.795-3.096-.268-4.183.911-1.746 1.896-1.566 4.509-.844 6.805a29.378 29.378 0 001.926 4.684c.422.823.788 1.724 1.408 2.428 1.082 1.227 2.66.92 3.994.321 1.596-.725 3.162-1.627 4.53-2.753z"
					fill="#515146"
				/>
			</svg>
		}
	/>
);

export default SvgEmotionGreatColor;
