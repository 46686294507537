// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgLocationOutine = (props: IconProps) => (
	<Icon
		title={'SvgLocationOutine'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 20 23"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<path
					d="M10 12.431a3.12 3.12 0 100-6.24 3.12 3.12 0 000 6.24z"
					stroke="#fff"
					strokeWidth={1.3}
				/>
				<path
					d="M1.62 7.49c1.97-8.66 14.8-8.65 16.76.01 1.15 5.08-2.01 9.38-4.78 12.04-3.46 3.46-3.75 3.46-7.21 0C3.63 16.88.47 12.57 1.62 7.49z"
					stroke="#fff"
					strokeWidth={1.3}
				/>
			</svg>
		}
	/>
);

export default SvgLocationOutine;
