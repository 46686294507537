// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgTypeVideoOutline = (props: IconProps) => (
	<Icon
		title={'SvgTypeVideoOutline'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<path
					d="M20.448 8.82v6.36a2.952 2.952 0 01-1.46 2.54l-5.524 3.19c-.902.52-2.018.52-2.93 0L5.01 17.72a2.929 2.929 0 01-1.46-2.54V8.82c0-1.042.558-2.01 1.46-2.54l5.524-3.19a2.942 2.942 0 012.93 0l5.524 3.19a2.94 2.94 0 011.46 2.54z"
					stroke="#2D3748"
					strokeWidth={1.2}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M9.911 12v-1.116c0-1.432 1.014-2.018 2.25-1.302l.968.558.967.558c1.237.716 1.237 1.888 0 2.604l-.967.558-.967.558c-1.237.716-2.25.13-2.25-1.302V12z"
					stroke="#2D3748"
					strokeWidth={1.2}
					strokeMiterlimit={10}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		}
	/>
);

export default SvgTypeVideoOutline;
