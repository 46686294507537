import React, { useState } from 'react';
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { Body2 } from '@pixiepkg/react';
import { GoogleSolid } from '@pixiepkg/icon';
import s from '@containers/LoginModal/styles/button.module.scss';
import { toast } from 'react-toastify';

interface Props {
	callback: (response: GoogleLoginResponse | GoogleLoginResponseOffline) => void;
}

export const GoogleLoginButton = ({ callback }: Props): JSX.Element => {
	const [isLoadFail, setIsLoadFail] = useState<boolean>(false);

	const onScriptLoadFailure = () => {
		toast.warn('Google sign in not available!');
		setIsLoadFail(true);
	};

	const onFailure = (res: any) => {
		if (res.error !== 'popup_closed_by_user') {
			toast.warn('Google sign in failed!');
		}
	};

	return process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID ? (
		<GoogleLogin
			clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}
			fetchBasicProfile={true}
			render={(renderProps) => (
				<button
					{...renderProps}
					disabled={renderProps.disabled || isLoadFail}
					onClick={renderProps.onClick}
					className={s.button}
				>
					<GoogleSolid size={24} />
					<Body2 medium className={s.text}>
						Continue with Google
					</Body2>
				</button>
			)}
			onFailure={onFailure}
			onSuccess={callback}
			onScriptLoadFailure={onScriptLoadFailure}
		/>
	) : (
		<button disabled className={s.button}>
			<GoogleSolid size={24} />
			<Body2 medium className={s.text}>
				Continue with Facebook
			</Body2>
		</button>
	);
};
