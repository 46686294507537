// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgCalendarColor = (props: IconProps) => (
	<Icon
		title={'SvgCalendarColor'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<path
					d="M19.93 21.58H4.07c-.917 0-1.66-.762-1.66-1.703V6.237c0-.941.743-1.704 1.66-1.704h15.86c.918 0 1.662.763 1.662 1.704v13.64c0 .941-.744 1.704-1.661 1.704z"
					fill="#fff"
				/>
				<path
					d="M19.931 4.533h-2.316c.917 0 1.66.763 1.66 1.704v13.64c0 .941-.743 1.704-1.66 1.704h2.316c.917 0 1.66-.763 1.66-1.704V6.237c0-.941-.743-1.704-1.66-1.704z"
					fill="#D4E7F8"
				/>
				<path
					d="M19.93 4.533H4.07c-.917 0-1.66.763-1.66 1.704v2.6h19.182v-2.6c0-.941-.744-1.704-1.661-1.704z"
					fill="#FD919E"
				/>
				<path
					d="M19.931 4.533h-2.316c.917 0 1.66.763 1.66 1.704v2.6h2.317v-2.6c0-.941-.744-1.704-1.661-1.704z"
					fill="#E8677D"
				/>
				<path
					d="M21.591 9.255H2.41a.404.404 0 01-.29-.123A.425.425 0 012 8.836v-2.6c0-1.17.928-2.123 2.07-2.123h15.86c1.141 0 2.07.953 2.07 2.123v2.6a.427.427 0 01-.12.296.408.408 0 01-.289.123zM2.817 8.416h18.366v-2.18c0-.708-.562-1.284-1.253-1.284H4.07c-.69 0-1.253.576-1.253 1.284v2.18z"
					fill="#4C4372"
				/>
				<path
					d="M19.93 22H4.07C2.927 22 2 21.047 2 19.877V6.236c0-1.171.928-2.124 2.07-2.124h15.86c1.141 0 2.07.953 2.07 2.123v13.64C22 21.047 21.07 22 19.93 22zM4.07 4.952c-.691 0-1.253.576-1.253 1.284v13.64c0 .709.562 1.285 1.252 1.285H19.93c.69 0 1.252-.576 1.252-1.285V6.236c0-.708-.561-1.284-1.252-1.284H4.07z"
					fill="#4C4372"
				/>
				<path
					d="M7.16 6.647a.844.844 0 01-.605-.257.889.889 0 01-.25-.62V3.297c0-.232.09-.456.25-.62a.844.844 0 01.605-.257c.227 0 .444.092.605.257.16.164.25.388.25.62V5.77c0 .233-.09.456-.25.62a.844.844 0 01-.605.257zm9.68 0a.844.844 0 01-.605-.257.89.89 0 01-.25-.62V3.297a.89.89 0 01.25-.62.844.844 0 01.605-.257c.227 0 .444.092.604.257a.89.89 0 01.25.62V5.77a.897.897 0 01-.25.62.853.853 0 01-.604.257z"
					fill="#4DB2FF"
				/>
				<path
					d="M7.16 7.066c-.697 0-1.264-.582-1.264-1.297V3.297C5.896 2.582 6.463 2 7.16 2c.697 0 1.264.582 1.264 1.297v2.472c0 .715-.567 1.297-1.264 1.297zm0-4.228a.441.441 0 00-.315.135.465.465 0 00-.131.323V5.77c0 .253.2.458.446.458a.453.453 0 00.447-.458V3.297a.465.465 0 00-.131-.324.442.442 0 00-.316-.135zm9.68 4.228c-.697 0-1.264-.582-1.264-1.297V3.297c0-.715.567-1.297 1.264-1.297.697 0 1.264.582 1.264 1.297v2.472c0 .715-.567 1.297-1.264 1.297zm0-4.228a.441.441 0 00-.316.135.465.465 0 00-.13.323V5.77c0 .253.2.458.446.458a.453.453 0 00.447-.458V3.297a.464.464 0 00-.131-.324.441.441 0 00-.316-.135z"
					fill="#4C4372"
				/>
			</svg>
		}
	/>
);

export default SvgCalendarColor;
