// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgTimeHourglassOutline = (props: IconProps) => (
	<Icon
		title={'SvgTimeHourglassOutline'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 14 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<path
					d="M10.016.7H3.99C.493.7.224 3.844 2.111 5.555l9.784 8.89c1.888 1.712 1.618 4.855-1.879 4.855H3.99c-3.497 0-3.766-3.143-1.879-4.854l9.784-8.891C13.783 3.844 13.513.7 10.016.7z"
					stroke="#2D3748"
					strokeWidth={1.2}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		}
	/>
);

export default SvgTimeHourglassOutline;
