import { PlacesType } from 'react-tooltip';

export const TOOLTIP_ID = 'p-tooltip';

interface TooltipProps {
	'data-tooltip-id': string;
	'data-tooltip-html': string;
	'data-tooltip-place': PlacesType;
	'data-tooltip-float'?: boolean;
}

export const getTooltipProps = (content: string): TooltipProps => ({
	'data-tooltip-id': TOOLTIP_ID,
	'data-tooltip-html': content,
	'data-tooltip-place': 'bottom',
	'data-tooltip-float': true
});
