import { EventPayload } from '@api/PayloadTypes';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { api } from '@api';
import TagManager from 'react-gtm-module';

export const useSendEvent = () => {
	const router = useRouter();

	// Update prev/cur url
	useEffect(() => {
		const _window: any = window;
		if (_window?.previousEventState?.current_url !== _window.location.href) {
			_window.previousEventState = {
				..._window.previousEventState,
				previous_url: _window?.previousEventState?.current_url,
				current_url: _window.location.href
			};
		}
	}, [router.asPath]);

	const sendEventGAAndBackend = useCallback((event: string, extra?: Record<string, any>) => {
		if (window) {
			const current_url = window.location.href;
			// Original url
			let original_url = window.location.href;
			const original_url_str = localStorage.getItem('original_url');
			if (original_url_str) {
				original_url = original_url_str;
			} else {
				localStorage.setItem('original_url', original_url);
			}

			const _window: any = window;
			const eventPayload: EventPayload = {
				current_url: current_url,
				original_url,
				previous_event: _window.previousEventState.previous_event,
				previous_url: _window.previousEventState.previous_url,
				event,
				extra
			};

			TagManager.dataLayer({
				dataLayer: eventPayload
			});

			api.sendEvent(eventPayload);

			_window.previousEventState.previous_event = event;
		}
	}, []);

	return { sendEventGAAndBackend };
};
