// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgPostcastOutline = (props: IconProps) => (
	<Icon
		title={'SvgPostcastOutline'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 16 18"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<path
					d="M9.775 8.45a1.838 1.838 0 00-2.233-2.233 1.84 1.84 0 00-1.325 1.325A1.838 1.838 0 008.45 9.775 1.814 1.814 0 009.775 8.45z"
					fill="#A0AEC0"
				/>
				<path
					d="M3.016 13.975a.636.636 0 01-.425-.167A7.954 7.954 0 01.05 7.992C.05 3.608 3.616.042 8 .042c4.383 0 7.95 3.566 7.95 7.95a7.881 7.881 0 01-2.508 5.791.629.629 0 01-.884-.025.629.629 0 01.025-.883A6.615 6.615 0 0014.7 8c0-3.692-3.008-6.7-6.7-6.7S1.3 4.308 1.3 8c0 1.883.758 3.617 2.141 4.9a.63.63 0 01.034.883.65.65 0 01-.459.192z"
					fill="#A0AEC0"
				/>
				<path
					d="M11.334 11.75a.621.621 0 01-.45-1.05A3.937 3.937 0 0011.959 8a3.953 3.953 0 00-3.95-3.95A3.958 3.958 0 004.059 8c0 1.008.383 1.967 1.075 2.7.233.25.225.65-.025.883a.621.621 0 01-.884-.025A5.19 5.19 0 012.81 8c0-2.867 2.333-5.2 5.2-5.2 2.866 0 5.2 2.333 5.2 5.2 0 1.325-.5 2.592-1.417 3.558a.65.65 0 01-.458.192zM6.583 12.883l-1.2 1.492c-.95 1.192-.1 2.958 1.417 2.958h2.392c1.525 0 2.375-1.766 1.416-2.95l-1.2-1.491a1.8 1.8 0 00-2.825-.009z"
					fill="#A0AEC0"
				/>
			</svg>
		}
	/>
);

export default SvgPostcastOutline;
