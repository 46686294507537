// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgTicketOutline = (props: IconProps) => (
	<Icon
		title={'SvgTicketOutline'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<path
					d="M10.14 4.56v14.88"
					stroke="#718096"
					strokeWidth={1.2}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeDasharray="5 5"
				/>
				<path
					d="M18.975 12.465A2.326 2.326 0 0121.3 10.14v-.93c0-3.72-.93-4.65-4.65-4.65h-9.3c-3.72 0-4.65.93-4.65 4.65v.465a2.326 2.326 0 010 4.65v.465c0 3.72.93 4.65 4.65 4.65h9.3c3.72 0 4.65-.93 4.65-4.65a2.326 2.326 0 01-2.325-2.325z"
					stroke="#2D3748"
					strokeWidth={1.2}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		}
	/>
);

export default SvgTicketOutline;
