// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgLanguageSolid = (props: IconProps) => (
	<Icon
		title={'SvgLanguageSolid'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 20 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<path
					d="M17.845 8.159h-7.531a2.157 2.157 0 00-2.155 2.155v4.091L6.208 15.8a.586.586 0 000 .954l1.975 1.41A2.158 2.158 0 0010.313 20h7.532A2.157 2.157 0 0020 17.845v-7.531a2.157 2.157 0 00-2.155-2.155zm-2.532 8.233c-.138 0-.245-.044-.276-.163l-.239-.835h-1.437l-.239.835c-.031.12-.138.163-.276.163-.22 0-.514-.138-.514-.339 0-.012.006-.037.012-.062l1.212-3.948c.056-.188.288-.276.52-.276.239 0 .471.088.528.276l1.211 3.948c.006.025.013.044.013.062 0 .195-.295.34-.515.34z"
					fill="#718096"
				/>
				<path
					d="M6.987 10.314c0-.787.275-1.51.734-2.08-.669 0-1.29-.21-1.8-.565a3.143 3.143 0 01-1.8.565.33.33 0 010-.661c.467 0 .906-.13 1.28-.355a3.152 3.152 0 01-.825-1.817H4.12a.33.33 0 110-.66h1.47v-.803a.33.33 0 11.66 0v.802h1.47a.33.33 0 010 .661h-.454a3.152 3.152 0 01-.827 1.817c.375.225.814.355 1.282.355a.33.33 0 01.33.306 3.314 3.314 0 012.263-.892h1.527V5.595L13.793 4.2a.586.586 0 000-.954l-1.975-1.41A2.159 2.159 0 009.686 0H2.155A2.157 2.157 0 000 2.155v7.531c0 1.188.967 2.155 2.155 2.155h4.832v-1.527z"
					fill="#718096"
				/>
			</svg>
		}
	/>
);

export default SvgLanguageSolid;
