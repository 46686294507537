// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgEmotionBadGray = (props: IconProps) => (
	<Icon
		title={'SvgEmotionBadGray'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 72 72"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<rect width={72} height={72} rx={36} fill="#CBD5E0" />
				<path
					d="M21.536 38.033c1.613 0 2.92-2.011 2.92-4.491 0-2.48-1.307-4.491-2.92-4.491s-2.92 2.01-2.92 4.49c0 2.481 1.307 4.492 2.92 4.492zM50.752 38.033c1.613 0 2.92-2.011 2.92-4.491 0-2.48-1.307-4.491-2.92-4.491s-2.92 2.01-2.92 4.49c0 2.481 1.307 4.492 2.92 4.492zM46.511 52.851c2.507 0 4.227-2.367 3.171-4.443-2.146-4.209-7.253-7.174-13.213-7.174-5.96 0-11.067 2.965-13.213 7.174-1.056 2.072.664 4.443 3.171 4.443h20.084zM8.147 26.676c.194.32.59.598 1.21.743 5.172 1.227 15.346-.339 16.178-6.29.136-.976-.414-3.352-1.949-2.221-1.614 1.188-2.723 2.841-4.39 4.007-1.636 1.144-3.55 1.773-5.626 1.707-1.345-.044-2.806-.317-4.125.057-1.188.334-1.729 1.284-1.298 1.997zM64.686 26.676c-.194.32-.59.598-1.21.743-5.172 1.227-15.346-.339-16.178-6.29-.136-.976.414-3.352 1.949-2.221 1.614 1.188 2.723 2.841 4.39 4.007 1.636 1.144 3.55 1.773 5.625 1.707 1.346-.044 2.807-.317 4.126.057 1.188.334 1.729 1.284 1.298 1.997z"
					fill="#fff"
				/>
			</svg>
		}
	/>
);

export default SvgEmotionBadGray;
