export enum Verdict {
	SUCCESS = 'success'
}

export interface APIResponse<T> {
	verdict: Verdict;
	data: T;
	time?: string;
	message?: string;
}

// PROFILES / ACCOUNTS

export enum OAuthProvider {
	Google = 'google',
	Facebook = 'facebook'
}

export interface SignInOAuthPayload {
	oauthToken: string;
	type: OAuthProvider;
}

export interface SignInOAuthResponse {
	token: string;
}

// P2Ps

export interface BookingP2PPayload {
	topic: string;
	targetID: string;
	startAt: number; // unix time
	durationSec: number;
	description: string;
	tags: Array<string>;
	contact: {
		firstName: string;
		lastName: string;
		email: string;
	};
	voucher?: string;
	extra?: Record<string, any>;
}

// WEBINARS

export interface JoinWebinarsPayload {
	voucher?: string;
}

export interface JoinWebinarsCollectionsPayload {
	voucher?: string;
}

// VOUCHERS

export interface GetAppliablePayload {
	type: string;
	resourceID?: string; // for webinar or webinar_collection
	expertID?: string; // for P2P
	duration?: number; // for P2P
}
export interface GetAppliableResponse {
	appliable: boolean;
	price: number;
	retry: number;
}

// Vacants

export interface CreateVacantPayload {
	dayOfWeek: 0 | 1 | 2 | 3 | 4 | 5 | 6;
	startTime: string; // time with time zone "11:30:00+07:00"
	endTime: string;
}

export interface ResultVacantData {
	id: string;
	startTime: number; // time with time zone "11:30:00+07:00"
	endTime: number;
	enable: boolean;
}

export interface VacantData {
	id: string;
	start: number;
	end: number;
	startTime: string; // time with time zone "11:30:00+07:00"
	endTime: string;
	enable: boolean;
}

// busy

export interface BusyData {
	id: string;
	date: number;
}

// Common (reviews)

export interface CreateReviewPayload {
	bookingID: string;
	expertID: string;
	bookingType: 'p2p' | 'webinar';
	emojis: string[];
	comment?: string;
}

// ORGANIZATION

// FILES

export interface GetUploadDownloadUrlResponse {
	uploadURL: string;
	downloadURL: string;
}

// Query types

// NOTIFICATIONS
export interface NotificationRegisterPayload {
	token: string;
	deviceID: string;
	type: string;
}
export interface NotificationRegister {
	data: null;
	message: string;
	time: string;
	verdict: string;
}

export interface SurveysExpert {
	question: string;
	name: string;
	email: string;
	phone: string;
	extra: {
		reason: string;
	};
}

export interface EventPayload {
	event: string;
	current_url: string;
	original_url: string;
	previous_url: string;
	previous_event: string;
	extra?: Record<string, any>;
}

export interface TopicSettingPayload {
	name: string;
	description: string;
	extra?: any[];
}

export interface TopicSettingResponse {
	id: string;
	name: string;
	description: string;
	extra: any[];
	createdAt: number;
}

export interface IChatPayload {
	message: string;
}

export interface IChatResponse {
	accountID?: string;
	createdAt?: number;
	id: string;
	role?: string;
	streaming: boolean;
	text: string | [string];
	type?: 'old' | 'new';
}

export interface IChatState {
	accountID?: string;
	createdAt?: number;
	id: string;
	role?: string;
	streaming: boolean;
	text: [string];
	type?: 'old' | 'new';
}
