import React, { Fragment, useState } from 'react';
import { Transition, Dialog } from '@headlessui/react';
import { Body2, Button, H5, KindButton, Body3 } from '@pixiepkg/react';
import s from '@containers/LoginModal/styles/modal.module.scss';
import { CloseOutline } from '@pixiepkg/icon';
import { AstronautColored } from '@components/Icon';
import { FacebookLoginButton } from './components/FacebookLoginButton';
import { GoogleLoginButton } from './components/GoogleLoginButton';
import { api } from '@api';
import { SignInOAuthPayload, OAuthProvider } from '@api/PayloadTypes';
import { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { toast } from 'react-toastify';
import { sessionAtom } from '@shared/stores/session.store';
import { SetStateAction, useAtom } from 'jotai';
import {
	ReactFacebookFailureResponse,
	ReactFacebookLoginInfo
} from 'react-facebook-login/dist/facebook-login-render-props';
import { BeatLoader } from 'react-spinners';
import { useSendEvent } from '@shared/hooks/useSendEvent';
import Link from 'next/link';

interface Props {
	loginModal: {
		isOpen: boolean;
		from: string;
	};
	setLoginModal: (
		update: SetStateAction<{
			isOpen: boolean;
			from: string;
		}>
	) => void;
}

export const LoginModal = ({ loginModal, setLoginModal }: Props) => {
	const { sendEventGAAndBackend } = useSendEvent();
	const [session, setSession] = useAtom(sessionAtom);
	const [loading, setLoading] = useState(false);
	function closeModal() {
		setLoginModal({
			...loginModal,
			isOpen: false
		});
	}

	const onGoogleCallback = (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
		setLoading(true);
		response = response as GoogleLoginResponse;
		(async () => {
			const payload: SignInOAuthPayload = {
				oauthToken: response.tokenId,
				type: OAuthProvider.Google
			};
			try {
				api.removeAuthHeader();
				const token = await api
					.signInOAuth(payload)
					.then((res) => {
						return res.data.data;
					})
					.then((data) => data.token);
				api.setToken(token);
				const user = await api.getAuthenticatedProfile().then((res) => res.data);
				setSession({
					...session,
					isSignedIn: true,
					isFetched: true,
					accessToken: token,
					user
				});
				toast.success('Sign in successful!');
				sendEventGAAndBackend('login_success', {
					login_type: 'google',
					from: loginModal.from
				});
			} catch (error) {
				toast.warn('Can not login by google, please try again later!');
			}
			setLoginModal({
				...loginModal,
				isOpen: false
			});
			setLoading(false);
		})();
	};

	const onFacebookCallback = (
		response: ReactFacebookLoginInfo | ReactFacebookFailureResponse
	) => {
		setLoading(true);
		const fbRes = response as ReactFacebookLoginInfo;
		if (fbRes.accessToken) {
			(async () => {
				const payload: SignInOAuthPayload = {
					oauthToken: fbRes.accessToken,
					type: OAuthProvider.Facebook
				};
				try {
					api.removeAuthHeader();
					const token = await api
						.signInOAuth(payload)
						.then((res) => {
							return res.data.data;
						})
						.then((data) => data.token);
					api.setToken(token);
					const user = await api.getAuthenticatedProfile().then((res) => res.data);
					setSession({
						...session,
						isSignedIn: true,
						isFetched: true,
						accessToken: token,
						user
					});
					toast.success('Sign in successful!');
					sendEventGAAndBackend('login_success', {
						login_type: 'facebook',
						from: loginModal.from
					});
				} catch (error) {
					toast.warn('Can not login by facebook, please try again later!');
					setLoading(false);
				}
				setLoading(false);
				setLoginModal({
					...loginModal,
					isOpen: false
				});
			})();
		} else if ((fbRes as any).status && (fbRes as any).status !== 'unknown') {
			toast.warn('Can not login by facebook, please try again later!');
		}
		setLoading(false);
	};

	return (
		<Transition appear show={loginModal.isOpen} as={Fragment}>
			<Dialog
				as="div"
				className="fixed inset-0 z-30 mt-8 overflow-y-auto"
				onClose={closeModal}
			>
				<div className="min-h-screen px-4 text-center">
					<Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

					{/* This element is to trick the browser into centering the modal contents. */}
					<span className="inline-block h-screen align-middle" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-50 scale-90"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-300 scale-100"
						leaveTo="opacity-50 scale-90"
					>
						<div className={s.modal}>
							<div className={s.title}>
								<H5 bold>Sign up / Login</H5>
								<Button
									kind={KindButton.GHOST}
									className={s.close}
									onClick={closeModal}
								>
									<CloseOutline size={32} />
								</Button>
							</div>
							<div className={s.content}>
								<div className="flex">
									<AstronautColored size={56} />
									<Body2 medium className="ml-4 text-left">
										Quick sign in to continue discovering Pixie, by using:
									</Body2>
								</div>
								{loading ? (
									<div className="mt-10 flex h-28 w-full items-center justify-center">
										<BeatLoader />
									</div>
								) : (
									<>
										<div className="mt-10 space-y-2">
											<GoogleLoginButton callback={onGoogleCallback} />
										</div>
										<div className="mt-2">
											<FacebookLoginButton callback={onFacebookCallback} />
										</div>
									</>
								)}
								<Body3 semiBold className="text-md pt-2">
									By continuing you agree to Pixie’s{' '}
									<Link
										className="text-brand-600"
										href="/terms-of-service"
										target="_blank"
									>
										terms of service
									</Link>{' '}
									and{' '}
									<Link
										className="text-brand-600"
										href="/privacy-policy"
										target="_blank"
									>
										privacy policy
									</Link>
								</Body3>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
};
