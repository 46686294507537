// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgArrowCloseOutline = (props: IconProps) => (
	<Icon
		title={'SvgArrowCloseOutline'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 22 15"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<path
					d="M11.711 1l6.539 6.538-6.539 6.539m5.385-6.539H1.75"
					stroke="#2D3748"
					strokeWidth={1.6}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path d="M20.25 1v13.08" stroke="#2D3748" strokeWidth={1.6} strokeLinecap="round" />
			</svg>
		}
	/>
);

export default SvgArrowCloseOutline;
