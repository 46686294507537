// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgPixieSolid2 = (props: IconProps) => (
	<Icon
		title={'SvgPixieSolid2'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 88 48"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<path
					d="M8.966 25.49l-.002-.002a2.535 2.535 0 00-3.585 0l-3.586 3.586c-.99.99-.99 2.595 0 3.585l.001.002c.99.99 2.595.99 3.585 0l3.587-3.587c.99-.99.99-2.595 0-3.585z"
					fill="#444CFF"
				/>
				<path
					d="M19.724 14.733l-.001-.001a2.535 2.535 0 00-3.585 0l-3.587 3.586c-.99.99-.99 2.595 0 3.585l.002.002c.99.99 2.595.99 3.585 0l3.586-3.587c.99-.99.99-2.595 0-3.585z"
					fill="#1CE6E3"
				/>
				<path
					d="M8.965 21.905l.001-.002c.99-.99.99-2.595 0-3.585L5.38 14.73a2.535 2.535 0 00-3.585 0l-.002.002c-.99.99-.99 2.595 0 3.585l3.587 3.587c.99.99 2.595.99 3.585 0z"
					fill="#FF5166"
				/>
				<path
					d="M19.72 32.66l.002-.001c.99-.99.99-2.595 0-3.585l-3.587-3.587a2.535 2.535 0 00-3.585 0l-.001.002c-.99.99-.99 2.595 0 3.585l3.586 3.587c.99.99 2.595.99 3.585 0z"
					fill="#FEEA3D"
				/>
				<path
					d="M31.79 28.527v7.355a1.528 1.528 0 01-1.32 1.496 1.467 1.467 0 01-1.623-1.452V22.582a7.448 7.448 0 112.934 5.945h.01zm0-5.95v.036a4.518 4.518 0 102.978-4.292 4.463 4.463 0 00-2.978 4.256zM45.886 28.414V16.49a1.466 1.466 0 011.623-1.45 1.525 1.525 0 011.32 1.496v11.932a1.467 1.467 0 01-1.807 1.445 1.52 1.52 0 01-1.136-1.499zM68.03 28.414V16.49a1.466 1.466 0 011.621-1.45 1.523 1.523 0 011.313 1.496v11.932a1.467 1.467 0 01-1.804 1.445 1.521 1.521 0 01-1.13-1.499zM88 22.477a1.466 1.466 0 01-1.484 1.466H76.261a4.532 4.532 0 004.28 3.06 5.85 5.85 0 003.33-.977c.682-.447 1.604-.032 1.956.682.352.714-.03 1.516-.714 1.956-1.956 1.307-3.476 1.276-4.576 1.276a7.461 7.461 0 110-14.912c3.6-.024 7.463 2.591 7.463 7.449zM76.266 20.99h8.59c-.625-2.08-2.527-3.031-4.31-3.031a4.546 4.546 0 00-4.285 3.019l.005.012zM53.499 15.462a1.495 1.495 0 10-2.114 2.114l11.962 11.961a1.495 1.495 0 102.114-2.114L53.499 15.462z"
					fill="#21282E"
				/>
				<path
					d="M65.416 17.62a1.495 1.495 0 10-2.114-2.113l-11.87 11.87a1.495 1.495 0 102.114 2.114l11.87-11.87zM46.301 12.537a1.51 1.51 0 01-.447-1.041 1.467 1.467 0 012.527-1.042c.27.28.42.653.419 1.042.002.388-.148.762-.419 1.041a1.503 1.503 0 01-2.08 0zM68.451 12.537a1.519 1.519 0 01-.445-1.041 1.468 1.468 0 012.528-1.042c.268.28.417.654.415 1.042.003.388-.147.761-.415 1.041a1.506 1.506 0 01-2.083 0z"
					fill="#21282E"
				/>
			</svg>
		}
	/>
);

export default SvgPixieSolid2;
