// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgCloseOutline = (props: IconProps) => (
	<Icon
		title={'SvgCloseOutline'}
		{...props}
		children={
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="close-outline_svg__ionicon"
				viewBox="0 0 512 512"
				width="1em"
				height="1em"
				{...props}
			>
				<path
					fill="none"
					stroke="currentColor"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={32}
					d="M368 368L144 144m224 0L144 368"
				/>
			</svg>
		}
	/>
);

export default SvgCloseOutline;
