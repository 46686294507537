export const getCookie = (name: string) => {
	const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
	if (match) return match[2];
};

const locales = ['vi', 'en'];
export const validLocale = (locale?: string) => {
	if (!locale) return false;
	return locales.includes(locale);
};
