// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgHandColor = (props: IconProps) => (
	<Icon
		title={'SvgHandColor'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 14 14"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<path
					d="M13.698 7.49l-4.662 4.838a3.91 3.91 0 01-2.08.942c-2.427.316-4.206-1.482-4.206-3.553 0-.954-.252-1.891-.729-2.717C.376 4.2.471 4.365.453 4.33a.869.869 0 011.316-.02 192.067 192.067 0 011.84 3.15.354.354 0 00.343-.445c-1.298-4.843-1.237-4.542-1.228-4.75a.984.984 0 01.747-.912c.336.082.618.34.714.697l1.28 4.782c0-6.133-.006-5.634.016-5.763A.98.98 0 015.84.473a.978.978 0 011.582.77V7c1.586-5.532 1.5-5.241 1.515-5.28a.978.978 0 011.642-.326c.033.038.15-.463-1.636 6.308v1.155c0 .332.37.653.8.487.287-.113.448-.344.669-.6.993-1.15.874-1.014.943-1.087.29-.28.666-.497 1.099-.597.182-.041.47-.078.646-.051.239.037.436.181.547.375.031.051.05.102.051.105z"
					fill="#FFCA9A"
				/>
			</svg>
		}
	/>
);

export default SvgHandColor;
