'use client';
import { useState } from 'react';
import { debounce } from 'lodash';
import tailwindConfig from '../../../tailwind.config';
import { useIsomorphicLayoutEffect } from 'react-use';

const DELAY_TIME = 20;

type SIZES = keyof typeof tailwindConfig.theme.screens;

// { [size]: pixels } - {xs: 786}
export const SCREEN_SIZES: Record<SIZES, number> = Object.entries(
	tailwindConfig.theme.screens
).reduce((acc: Record<string, number>, [key, value]) => {
	acc[key] = parseInt(value.max.replace('px', ''));
	return acc;
}, {});

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height
	};
}

export const useWindowSize = () => {
	const [{ width, height }, setWindowDimensions] = useState({
		width: 0,
		height: 0
	});

	useIsomorphicLayoutEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}
		handleResize();
		const debouncedHandleResize = debounce(handleResize, DELAY_TIME);
		window.addEventListener('resize', debouncedHandleResize);
		return () => window.removeEventListener('resize', debouncedHandleResize);
	}, []);

	return {
		width,
		height
	};
};
