import '@pixiepkg/react/dist/bundle.css';
import '@pixiepkg/icon/dist/bundle.css';
import '@pixiepkg/react/dist/font/font.css';
import 'nprogress/nprogress.css';
import 'react-loading-skeleton/dist/skeleton.css';
import '@styles/globals.css';
import '@styles/mixin.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';

import type { AppProps } from 'next/app';
import { appWithTranslation } from 'next-i18next';
import React, { useEffect } from 'react';
import { DefaultSeo } from 'next-seo';
import { ToastContainer } from 'react-toastify';
import { LoginModal } from '@containers/LoginModal';
import { useAtom } from 'jotai';
import { loginModal } from '@shared/stores/loginModal.store';
import { CookiesProvider } from 'react-cookie';
import { api } from '@api';
import { v4 as uuid } from 'uuid';
import firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/messaging';
import { sessionAtom } from '@shared/stores/session.store';
import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { init } from '@sentry/nextjs';
import { TOOLTIP_ID } from '@shared/constant/tooltip';
import { NonSSRWrapper } from '@components/NoSSRWrapper';
import { Tooltip } from 'react-tooltip';
import Hotjar from '@hotjar/browser';
import { ChatGPTMessage } from '@components/ChatGPT';
import Head from 'next/head';
import { Router } from 'next/router';
import Nprogress from 'nprogress';

const initHotjar = () => {
	// Hotjar
	const siteId = 3547770;
	const hotjarVersion = 6;
	Hotjar.init(siteId, hotjarVersion);
	// -----------------------------------------
};
const initializePushNotification = () => {
	const firebaseConfig = {
		apiKey: process.env.NEXT_PUBLIC_FIREBASE_KEY,
		authDomain: 'pixiespirit-staging.firebaseapp.com',
		projectId: 'pixiespirit-staging',
		storageBucket: 'pixiespirit-staging.appspot.com',
		messagingSenderId: '792894301742',
		appId: '1:792894301742:web:262327a5c99bab9545a1bf',
		measurementId: 'G-F781TEW3PJ'
	};

	if (!firebase.apps.length) {
		firebase.initializeApp(firebaseConfig);
	} else {
		firebase.app(); // if already initialized, use that one
	}

	if ('serviceWorker' in navigator) {
		const firebaseURL =
			window.location.hostname === 'localhost'
				? `http://localhost:3000/firebase-messaging-sw.js`
				: `https://${window.location.hostname}/firebase-messaging-sw.js`;
		navigator.serviceWorker.register(firebaseURL).then(() => {
			firebase
				.messaging()
				.getToken({
					vapidKey:
						'BBlZXPxLh02Epj0IeSXAf8AOo6D2bBo4bC_PvQlb-ajj-zCBeT-KKPIwvC3TYuOc4R5euP-eggVUdOW0e0n2y9g'
				})
				.then((currentToken: string) => {
					if (currentToken) {
						let deviceID = window.localStorage.getItem('PROFILE_UUID') || undefined;
						if (!deviceID) {
							window.localStorage.setItem('PROFILE_UUID', uuid());
							deviceID = window.localStorage.getItem('PROFILE_UUID') || uuid();
						}
						// const deviceType = navigator.platform;
						// Send the token to BE
						api.notificationRegister({
							token: currentToken,
							deviceID: deviceID,
							type: 'web'
						});
					} else {
						console.log(
							'No registration token available. Request permission to generate one.'
						);
					}
				})
				.catch((error) => {
					if (error.code === 'messaging/permission-blocked') {
						console.log('Please Unblock Notification Request Manually');
					} else {
						console.log('Error Occurred', error);
					}
				});
		});
	}
};

Router.events.on('routeChangeStart', () => {
	Nprogress.start();
});

Router.events.on('routeChangeComplete', () => {
	Nprogress.done(false);
});

function MyApp({ Component, pageProps }: AppProps) {
	const [openLoginModal, setOpenLoginModal] = useAtom(loginModal);
	const [session] = useAtom(sessionAtom);

	// FCM push noti
	useEffect(() => {
		if (session?.accessToken && session.isFetched && session.isSignedIn) {
			initializePushNotification();
		}
	}, [session]);

	// Google tag manager
	useEffect(() => {
		if (process.env.NEXT_PUBLIC_ENV === 'PRODUCTION') {
			const tagManagerArgs: TagManagerArgs = {
				gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG || ''
			};

			TagManager.initialize(tagManagerArgs);

			init({
				dsn: process.env.NEXT_PUBLIC_SENTRY,
				environment: process.env.NEXT_PUBLIC_ENV
			});
			// Init họtjar here
			initHotjar();
		}
	}, []);

	return (
		<>
			<Head>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
				/>
			</Head>
			<DefaultSeo
				title="Pixie"
				description="Nền tảng trực tuyến kết nối bạn và các chuyên gia"
				additionalLinkTags={[
					{
						rel: 'icon',
						type: 'image/svg+xml',
						href: '/images/favicon.svg'
					}
				]}
				openGraph={{
					title: 'Nền tảng trực tuyến kết nối bạn và các chuyên gia',
					description:
						'Pixie giúp bạn kết nối, học hỏi & chia sẻ kinh nghiệm thông qua các buổi chia sẻ trực tuyến',
					type: 'website',
					site_name: 'Pixie',
					images: [
						{
							url: '/images/thumbnail.png',
							alt: 'thumpnail'
						}
					]
				}}
			/>
			<ToastContainer
				position="bottom-right"
				limit={5}
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				draggable
				pauseOnHover
			/>
			<div id="light-box"></div>
			<div id="fb-root"></div>
			<NonSSRWrapper>
				<Tooltip id={TOOLTIP_ID} style={{ zIndex: 9999 }} />
				<ChatGPTMessage />
			</NonSSRWrapper>

			<LoginModal loginModal={openLoginModal} setLoginModal={setOpenLoginModal} />
			<CookiesProvider>
				<Component {...pageProps} />
			</CookiesProvider>
		</>
	);
}
export default appWithTranslation(MyApp);
