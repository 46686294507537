// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgWallet = (props: IconProps) => (
	<Icon
		title={'SvgWallet'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 73 81"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<path
					d="M50.207 16.492L30.07 11.097c-7.304-1.957-12.702 1.18-14.666 8.508l-6.65 24.819c-1.996 7.448 1.137 12.768 8.44 14.725l20.137 5.396c7.422 1.988 12.674-1.085 14.67-8.533l6.65-24.82c1.964-7.327-1.023-12.711-8.444-14.7z"
					fill="url(#Wallet_svg__paint0_linear_1525_770)"
				/>
				<g opacity={0.5} filter="url(#Wallet_svg__filter0_f_1525_770)">
					<path
						d="M27.99 37.128a8.173 8.173 0 010-16.255l5.977-.636c5.206-.553 9.745 3.528 9.745 8.764 0 5.236-4.539 9.317-9.745 8.763l-5.977-.636z"
						fill="#0062FF"
					/>
				</g>
				<g filter="url(#Wallet_svg__filter1_b_1525_770)">
					<path
						d="M65.936 41.819c0-10.13-6.141-16.096-16.533-16.096h-27.23c-10.394 0-16.534 5.966-16.534 16.096V63.88C5.639 74.01 11.779 80 22.174 80h27.24c10.392 0 16.532-5.99 16.532-16.12 0-10.129-.01-20.942-.01-22.061z"
						fill="#3EA0FE"
						fillOpacity={0.35}
					/>
					<path
						d="M66.436 41.819c0-5.167-1.569-9.332-4.521-12.205-2.95-2.87-7.221-4.391-12.512-4.391h-27.23c-5.29 0-9.563 1.52-12.513 4.391-2.953 2.873-4.521 7.038-4.521 12.205V63.88c0 5.166 1.568 9.337 4.52 12.216 2.95 2.877 7.223 4.403 12.515 4.403h27.24c5.29 0 9.562-1.526 12.512-4.403 2.952-2.879 4.52-7.05 4.52-12.216 0-5.065-.002-10.301-.005-14.41l-.004-5.162-.001-2.49z"
						stroke="url(#Wallet_svg__paint1_linear_1525_770)"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
				<g filter="url(#Wallet_svg__filter2_b_1525_770)">
					<path
						d="M44.185 54.215c.813 3.687 4.06 6.282 7.765 6.214h16.62c1.542 0 2.792-1.272 2.792-2.844V47.77c-.003-1.568-1.25-2.844-2.792-2.847H51.652c-4.215.014-7.62 3.499-7.613 7.794 0 .502.05 1.005.146 1.498z"
						fill="#3EA0FE"
						fillOpacity={0.35}
					/>
					<path
						d="M43.694 54.31h0l.002.012c.864 3.913 4.31 6.677 8.258 6.607H68.57c1.826 0 3.292-1.504 3.292-3.344V47.77h0c-.004-1.835-1.465-3.343-3.291-3.347H51.65c-4.5.014-8.118 3.732-8.11 8.294 0 .535.052 1.07.154 1.593z"
						stroke="url(#Wallet_svg__paint2_linear_1525_770)"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
				<g filter="url(#Wallet_svg__filter3_bd_1525_770)">
					<circle
						cx={51.792}
						cy={52.677}
						fill="url(#Wallet_svg__paint3_linear_1525_770)"
						r={3.323}
					/>
					<path
						d="M55.015 52.677a3.223 3.223 0 11-6.446 0 3.223 3.223 0 016.446 0z"
						stroke="url(#Wallet_svg__paint4_linear_1525_770)"
						strokeOpacity={0.5}
						strokeWidth={0.2}
					/>
				</g>
				<g filter="url(#Wallet_svg__filter4_bd_1525_770)">
					<path
						d="M36.958 41.913H19.951c-1.273-.005-2.302-1.016-2.297-2.258.006-1.236 1.032-2.237 2.297-2.242H36.97c1.273-.005 2.313.998 2.318 2.24.006 1.244-1.023 2.255-2.297 2.26h-.031z"
						fill="url(#Wallet_svg__paint5_linear_1525_770)"
					/>
					<path
						d="M36.969 37.513h0c1.22-.005 2.213.956 2.218 2.14.006 1.186-.977 2.155-2.197 2.16H19.952c-1.22-.005-2.203-.974-2.198-2.157.005-1.18.985-2.138 2.198-2.143h17.017z"
						stroke="url(#Wallet_svg__paint6_linear_1525_770)"
						strokeOpacity={0.5}
						strokeWidth={0.2}
					/>
				</g>
				<defs>
					<linearGradient
						id="Wallet_svg__paint0_linear_1525_770"
						x1={58.668}
						y1={18.759}
						x2={2.306}
						y2={35.834}
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#39AFFD" />
						<stop offset={1} stopColor="#477FFF" />
					</linearGradient>
					<linearGradient
						id="Wallet_svg__paint1_linear_1525_770"
						x1={15.239}
						y1={32.046}
						x2={59.278}
						y2={81.893}
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#fff" stopOpacity={0.25} />
						<stop offset={1} stopColor="#fff" stopOpacity={0} />
					</linearGradient>
					<linearGradient
						id="Wallet_svg__paint2_linear_1525_770"
						x1={48.389}
						y1={46.73}
						x2={59.17}
						y2={66.081}
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#fff" stopOpacity={0.25} />
						<stop offset={1} stopColor="#fff" stopOpacity={0} />
					</linearGradient>
					<linearGradient
						id="Wallet_svg__paint3_linear_1525_770"
						x1={54.644}
						y1={50.554}
						x2={47.24}
						y2={50.795}
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#fff" />
						<stop offset={1} stopColor="#fff" stopOpacity={0.2} />
					</linearGradient>
					<linearGradient
						id="Wallet_svg__paint4_linear_1525_770"
						x1={49.185}
						y1={51.583}
						x2={54.828}
						y2={51.614}
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#fff" />
						<stop offset={1} stopColor="#fff" stopOpacity={0} />
					</linearGradient>
					<linearGradient
						id="Wallet_svg__paint5_linear_1525_770"
						x1={37.754}
						y1={38.226}
						x2={14.209}
						y2={41.916}
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#fff" />
						<stop offset={1} stopColor="#fff" stopOpacity={0.2} />
					</linearGradient>
					<linearGradient
						id="Wallet_svg__paint6_linear_1525_770"
						x1={19.985}
						y1={38.923}
						x2={38.342}
						y2={39.41}
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#fff" />
						<stop offset={1} stopColor="#fff" stopOpacity={0} />
					</linearGradient>
					<filter
						id="Wallet_svg__filter0_f_1525_770"
						x={0.682}
						y={0.187}
						width={63.029}
						height={57.627}
						filterUnits="userSpaceOnUse"
						colorInterpolationFilters="sRGB"
					>
						<feFlood floodOpacity={0} result="BackgroundImageFix" />
						<feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
						<feGaussianBlur
							stdDeviation={10}
							result="effect1_foregroundBlur_1525_770"
						/>
					</filter>
					<filter
						id="Wallet_svg__filter1_b_1525_770"
						x={-19.361}
						y={0.723}
						width={110.309}
						height={104.277}
						filterUnits="userSpaceOnUse"
						colorInterpolationFilters="sRGB"
					>
						<feFlood floodOpacity={0} result="BackgroundImageFix" />
						<feGaussianBlur in="BackgroundImageFix" stdDeviation={12} />
						<feComposite
							in2="SourceAlpha"
							operator="in"
							result="effect1_backgroundBlur_1525_770"
						/>
						<feBlend
							in="SourceGraphic"
							in2="effect1_backgroundBlur_1525_770"
							result="shape"
						/>
					</filter>
					<filter
						id="Wallet_svg__filter2_b_1525_770"
						x={19.039}
						y={19.923}
						width={77.322}
						height={65.508}
						filterUnits="userSpaceOnUse"
						colorInterpolationFilters="sRGB"
					>
						<feFlood floodOpacity={0} result="BackgroundImageFix" />
						<feGaussianBlur in="BackgroundImageFix" stdDeviation={12} />
						<feComposite
							in2="SourceAlpha"
							operator="in"
							result="effect1_backgroundBlur_1525_770"
						/>
						<feBlend
							in="SourceGraphic"
							in2="effect1_backgroundBlur_1525_770"
							result="shape"
						/>
					</filter>
					<filter
						id="Wallet_svg__filter3_bd_1525_770"
						x={33.469}
						y={34.354}
						width={36.647}
						height={36.646}
						filterUnits="userSpaceOnUse"
						colorInterpolationFilters="sRGB"
					>
						<feFlood floodOpacity={0} result="BackgroundImageFix" />
						<feGaussianBlur in="BackgroundImageFix" stdDeviation={7.5} />
						<feComposite
							in2="SourceAlpha"
							operator="in"
							result="effect1_backgroundBlur_1525_770"
						/>
						<feColorMatrix
							in="SourceAlpha"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
							result="hardAlpha"
						/>
						<feOffset dx={5} dy={5} />
						<feGaussianBlur stdDeviation={5} />
						<feColorMatrix values="0 0 0 0 0.15375 0 0 0 0 0.522433 0 0 0 0 0.9 0 0 0 0.5 0" />
						<feBlend
							in2="effect1_backgroundBlur_1525_770"
							result="effect2_dropShadow_1525_770"
						/>
						<feBlend
							in="SourceGraphic"
							in2="effect2_dropShadow_1525_770"
							result="shape"
						/>
					</filter>
					<filter
						id="Wallet_svg__filter4_bd_1525_770"
						x={2.654}
						y={22.413}
						width={51.633}
						height={34.5}
						filterUnits="userSpaceOnUse"
						colorInterpolationFilters="sRGB"
					>
						<feFlood floodOpacity={0} result="BackgroundImageFix" />
						<feGaussianBlur in="BackgroundImageFix" stdDeviation={7.5} />
						<feComposite
							in2="SourceAlpha"
							operator="in"
							result="effect1_backgroundBlur_1525_770"
						/>
						<feColorMatrix
							in="SourceAlpha"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
							result="hardAlpha"
						/>
						<feOffset dx={5} dy={5} />
						<feGaussianBlur stdDeviation={5} />
						<feColorMatrix values="0 0 0 0 0.15375 0 0 0 0 0.522433 0 0 0 0 0.9 0 0 0 0.5 0" />
						<feBlend
							in2="effect1_backgroundBlur_1525_770"
							result="effect2_dropShadow_1525_770"
						/>
						<feBlend
							in="SourceGraphic"
							in2="effect2_dropShadow_1525_770"
							result="shape"
						/>
					</filter>
				</defs>
			</svg>
		}
	/>
);

export default SvgWallet;
