// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgLocationSolid = (props: IconProps) => (
	<Icon
		title={'SvgLocationSolid'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 16 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<path
					d="M15.775 6.275C14.828 1.948 11.195 0 8.005 0h-.01C4.814 0 1.172 1.939.225 6.266-.829 11.098 2.02 15.19 4.597 17.767 5.553 18.722 6.774 20 8 20s2.456-1.278 3.403-2.233c2.578-2.576 5.426-6.66 4.372-11.492zm-7.77 4.692c-1.569 0-2.84-1.32-2.84-2.95 0-1.63 1.271-2.95 2.84-2.95 1.568 0 2.839 1.32 2.839 2.95 0 1.63-1.271 2.95-2.84 2.95z"
					fill="#718096"
				/>
			</svg>
		}
	/>
);

export default SvgLocationSolid;
