// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgGpt = (props: IconProps) => (
	<Icon
		title={'SvgGpt'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 64 64"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<rect x={4} y={4} width={56} height={56} rx={28} fill="#10A37F" />
				<path
					d="M45.956 29.687a7.625 7.625 0 00-.672-6.343A7.88 7.88 0 0041.64 20.1a8.014 8.014 0 00-4.882-.502 7.822 7.822 0 00-2.667-1.928A7.916 7.916 0 0030.855 17a7.988 7.988 0 00-4.67 1.49 7.817 7.817 0 00-2.882 3.918 7.881 7.881 0 00-3.027 1.315 7.759 7.759 0 00-2.206 2.431 7.726 7.726 0 00.973 9.159 7.626 7.626 0 00.673 6.343 7.879 7.879 0 003.643 3.245 8.014 8.014 0 004.883.502c.74.823 1.649 1.48 2.667 1.928a7.917 7.917 0 003.236.669 7.987 7.987 0 004.671-1.49 7.817 7.817 0 002.883-3.921 7.884 7.884 0 003.027-1.315 7.763 7.763 0 002.206-2.431 7.725 7.725 0 00-.976-9.156zM34.147 45.973a5.92 5.92 0 01-3.769-1.344c.048-.026.132-.071.186-.104l6.256-3.565a1.01 1.01 0 00.514-.879V31.38l2.644 1.507a.094.094 0 01.052.071v7.207a5.778 5.778 0 01-1.725 4.104 5.935 5.935 0 01-4.158 1.705zm-12.65-5.33a5.725 5.725 0 01-.702-3.894c.046.028.127.077.186.11l6.255 3.565a1.03 1.03 0 001.028 0l7.637-4.351v3.013a.092.092 0 01-.037.08l-6.324 3.603a5.963 5.963 0 01-4.467.577 5.884 5.884 0 01-3.576-2.703zm-1.645-13.476a5.847 5.847 0 013.064-2.547l-.003.213v7.13a.993.993 0 00.514.878l7.637 4.351-2.644 1.506a.096.096 0 01-.09.008l-6.324-3.605a5.826 5.826 0 01-2.739-3.527 5.748 5.748 0 01.585-4.407zm21.724 4.989l-7.638-4.352 2.644-1.505a.097.097 0 01.09-.008l6.324 3.602a5.846 5.846 0 012.276 2.344 5.745 5.745 0 01-.54 6.212 5.883 5.883 0 01-2.646 1.928v-7.343a.991.991 0 00-.51-.878zm2.631-3.909a8.356 8.356 0 00-.185-.109l-6.256-3.565a1.03 1.03 0 00-1.028 0l-7.637 4.351v-3.013a.092.092 0 01.037-.08l6.324-3.6a5.952 5.952 0 016.31.268 5.825 5.825 0 012.064 2.525 5.737 5.737 0 01.371 3.223zm-16.544 5.37l-2.645-1.506a.092.092 0 01-.051-.072v-7.206c0-1.104.32-2.184.92-3.116a5.865 5.865 0 012.471-2.142 5.96 5.96 0 016.265.797c-.048.025-.13.07-.186.104l-6.255 3.565a.993.993 0 00-.514.878l-.005 8.698zm1.436-3.055l3.402-1.939 3.402 1.938v3.875L32.5 36.374l-3.402-1.938v-3.874z"
					fill="#fff"
				/>
				<rect
					x={2}
					y={2}
					width={60}
					height={60}
					rx={30}
					stroke="#10A37F"
					strokeOpacity={0.18}
					strokeWidth={4}
				/>
			</svg>
		}
	/>
);
export default SvgGpt;
