// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgFollowSolid = (props: IconProps) => (
	<Icon
		title={'SvgFollowSolid'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 20 18"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<path
					d="M15 0H5C2.24 0 0 2.24 0 5v8c0 2.76 2.24 5 5 5h10c2.76 0 5-2.24 5-5V5c0-2.76-2.24-5-5-5zM8.49 13.81c-.03 0-.06.01-.1.01-.37 0-.69-.28-.74-.65-.13-1.02-.87-1.76-1.89-1.89a.753.753 0 01-.65-.84c.05-.41.43-.7.84-.65 1.69.22 2.97 1.5 3.19 3.19.05.4-.24.78-.65.83zm3.05-.51c-.03 0-.06.01-.1.01-.37 0-.69-.28-.74-.65-.31-2.38-2.04-4.12-4.43-4.43a.753.753 0 01-.65-.84c.05-.41.43-.7.84-.65 3.08.4 5.33 2.64 5.73 5.73.05.4-.24.77-.65.83zm2.7-.87c-.03 0-.06.01-.1.01-.37 0-.69-.28-.74-.65-.43-3.38-2.89-5.84-6.27-6.27a.753.753 0 01-.65-.84c.05-.41.43-.7.84-.65 4.07.52 7.04 3.49 7.57 7.57.05.41-.24.78-.65.83z"
					fill="#718096"
				/>
			</svg>
		}
	/>
);

export default SvgFollowSolid;
