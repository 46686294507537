// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgAcuteSolid = (props: IconProps) => (
	<Icon
		title={'SvgAcuteSolid'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 36 26"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M30.7 21.942c1.134-.678 2.263-1.343 3.378-2.054a2.07 2.07 0 00.639-2.825 2 2 0 00-2.783-.64c-1.083.69-2.18 1.335-3.281 1.995a2.068 2.068 0 00-.718 2.806 2.002 2.002 0 002.765.718zM21.94 15.788c1.304-3.957 2.41-7.977 3.631-11.96.33-1.076-.264-2.22-1.325-2.553a2.014 2.014 0 00-2.519 1.35c-1.213 3.954-2.308 7.947-3.605 11.876-.352 1.069.217 2.226 1.27 2.58 1.054.356 2.196-.224 2.548-1.293zM7.03 15.559c-.92-3.377-1.935-6.734-2.816-10.121a2.007 2.007 0 00-2.457-1.446C.684 4.282.044 5.4.326 6.488c.888 3.4 1.905 6.772 2.83 10.165a2.003 2.003 0 002.472 1.417c1.07-.3 1.699-1.427 1.403-2.511z"
					fill="#616BFF"
				/>
			</svg>
		}
	/>
);

export default SvgAcuteSolid;
