// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgLocation = (props: IconProps) => (
	<Icon
		title={'SvgLocation'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<path
					d="M19.775 8.275C18.828 3.948 15.195 2 12.005 2h-.01c-3.181 0-6.823 1.939-7.77 6.266-1.054 4.832 1.794 8.925 4.372 11.501C9.553 20.722 10.774 22 12 22s2.456-1.278 3.403-2.233c2.578-2.576 5.426-6.66 4.372-11.492zm-7.77 4.692c-1.569 0-2.84-1.32-2.84-2.95 0-1.63 1.271-2.95 2.84-2.95 1.568 0 2.839 1.32 2.839 2.95 0 1.63-1.271 2.95-2.84 2.95z"
					fill="#616BFF"
				/>
			</svg>
		}
	/>
);
export default SvgLocation;
