import React from 'react';
import s from '@components/Icon/styles/icon.module.css';

export interface IconProps {
  // default: inline
  display?: 'block' | 'inline';
  size?: number;
  color?: string;
  title?: string;
}

const getClass = (props: IconProps) => {
  const classes = [s.icon];

  switch (props.display) {
    case 'block':
      classes.push(s.block);
      break;
    case 'inline':
    default:
      classes.push(s.inlineBlock);
      break;
  }

  return classes.join(' ');
};

export const Icon: React.FC<IconProps> = (props) => {
  return (
    <span
      // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/Role_Img
      role="img"
      aria-label={props.title}
      // Stylings
      className={getClass(props)}
      style={{
        color: props.color,
        fontSize: props.size
      }}
    >
      {props.children}
    </span>
  );
};

export * from '@components/Icon/components';
