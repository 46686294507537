// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgArrowBackOutline = (props: IconProps) => (
	<Icon
		title={'SvgArrowBackOutline'}
		{...props}
		children={
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="arrow-back-outline_svg__ionicon"
				viewBox="0 0 512 512"
				width="1em"
				height="1em"
				{...props}
			>
				<path
					fill="none"
					stroke="currentColor"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={48}
					d="M244 400L100 256l144-144M120 256h292"
				/>
			</svg>
		}
	/>
);

export default SvgArrowBackOutline;
