// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgExpertSolid = (props: IconProps) => (
	<Icon
		title={'SvgExpertSolid'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 24 24"
				fill="currentColor"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<path
					opacity={0.4}
					d="M12 3C9.794 3 8 4.796 8 7.004c0 2.167 1.693 3.92 3.899 3.996a.681.681 0 01.185 0H12.143A3.992 3.992 0 0016 7.004 4.006 4.006 0 0012 3z"
					fill="currentColor"
					/>
				<path
					opacity={0.4}
					fillRule="evenodd"
					clipRule="evenodd"
					d="M6.93 14.16c2.81-1.86 7.36-1.86 10.15 0 1.26.83 1.95 1.98 1.96 3.2 0 .377-.064.744-.19 1.096-.306-.142-.596-.4-.732-.673l-.36-.732c-.693-1.401-1.823-1.401-2.516 0l-.36.732c-.18.36-.642.707-1.027.771l-.488.077c-1.463.245-1.81 1.324-.757 2.39l.45.45c.11.117.204.274.272.446-.44.055-.886.083-1.332.083-1.84 0-3.68-.47-5.08-1.41-1.26-.84-1.96-1.98-1.96-3.21s.7-2.38 1.97-3.22z"
					fill="currentColor"
					/>
				<path
					d="M17.199 18.584l.2.407c.1.2.35.385.57.428l.271.043c.813.136 1.006.735.421 1.328l-.25.25a.904.904 0 00-.206.728l.035.15c.221.985-.3 1.364-1.155.85l-.186-.108a.876.876 0 00-.798 0l-.186.107c-.863.522-1.384.136-1.155-.85l.035-.15a.904.904 0 00-.207-.727l-.25-.25c-.584-.593-.391-1.192.422-1.328l.27-.043a.887.887 0 00.571-.428l.2-.407c.385-.779 1.013-.779 1.398 0z"
					fill="currentColor"
					/>
			</svg>
		}
	/>
);

export default SvgExpertSolid;
