// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgVerifySolid = (props: IconProps) => (
	<Icon
		title={'SvgVerifySolid'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 21 22"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<path
					d="M19.56 9.74L18.2 8.16c-.26-.3-.47-.86-.47-1.26V5.2c0-1.06-.87-1.93-1.93-1.93h-1.7c-.39 0-.96-.21-1.26-.47l-1.58-1.36c-.69-.59-1.82-.59-2.52 0L7.17 2.81c-.3.25-.87.46-1.26.46H4.18c-1.06 0-1.93.87-1.93 1.93v1.71c0 .39-.21.95-.46 1.25L.44 9.75c-.58.69-.58 1.81 0 2.5l1.35 1.59c.25.3.46.86.46 1.25v1.71c0 1.06.87 1.93 1.93 1.93h1.73c.39 0 .96.21 1.26.47l1.58 1.36c.69.59 1.82.59 2.52 0l1.58-1.36c.3-.26.86-.47 1.26-.47h1.7c1.06 0 1.93-.87 1.93-1.93v-1.7c0-.39.21-.96.47-1.26l1.36-1.58c.58-.69.58-1.83-.01-2.52zm-5.4-.63l-4.83 4.83a.75.75 0 01-1.06 0l-2.42-2.42a.754.754 0 010-1.06c.29-.29.77-.29 1.06 0l1.89 1.89 4.3-4.3c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06z"
					fill="#718096"
				/>
			</svg>
		}
	/>
);

export default SvgVerifySolid;
