// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgPixieSolidDark = (props: IconProps) => (
	<Icon
		title={'SvgPixieSolidDark'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 118 56"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<path
					d="M11.955 29.985l-.002-.002a3.38 3.38 0 00-4.78 0L2.39 34.765a3.38 3.38 0 000 4.78l.002.002a3.38 3.38 0 004.78 0l4.783-4.782a3.38 3.38 0 000-4.78z"
					fill="#444CFF"
				/>
				<path
					d="M26.299 15.644l-.003-.002a3.38 3.38 0 00-4.78 0l-4.782 4.782a3.38 3.38 0 000 4.78l.002.003a3.38 3.38 0 004.78 0l4.783-4.783a3.38 3.38 0 000-4.78z"
					fill="#1CE6E3"
				/>
				<path
					d="M11.953 25.206l.003-.003a3.38 3.38 0 000-4.78l-4.783-4.782a3.38 3.38 0 00-4.78 0l-.002.002a3.38 3.38 0 000 4.78l4.782 4.783a3.38 3.38 0 004.78 0z"
					fill="#FF5166"
				/>
				<path
					d="M26.293 39.547l.003-.002a3.38 3.38 0 000-4.78l-4.783-4.782a3.38 3.38 0 00-4.78 0l-.002.002a3.38 3.38 0 000 4.78l4.782 4.782a3.38 3.38 0 004.78 0z"
					fill="#FEEA3D"
				/>
				<path
					d="M42.387 34.035v9.808a2.037 2.037 0 01-1.76 1.994 1.957 1.957 0 01-2.164-1.936V26.108a9.93 9.93 0 113.911 7.927h.013zm0-7.933v.049a6.024 6.024 0 103.97-5.724 5.951 5.951 0 00-3.97 5.675zM61.18 33.885V17.986a1.954 1.954 0 012.164-1.933 2.034 2.034 0 011.76 1.995v15.909a1.956 1.956 0 01-2.408 1.926 2.027 2.027 0 01-1.516-1.998zM90.707 33.885V17.986a1.956 1.956 0 012.161-1.933 2.03 2.03 0 011.75 1.995v15.91a1.956 1.956 0 01-2.405 1.925 2.027 2.027 0 01-1.506-1.998zM117.333 25.968a1.969 1.969 0 01-.581 1.391 1.953 1.953 0 01-1.398.565h-13.673a6.047 6.047 0 005.707 4.08 7.801 7.801 0 004.44-1.303c.909-.597 2.138-.043 2.607.91.47.95-.039 2.02-.952 2.607-2.607 1.743-4.634 1.701-6.101 1.701a9.946 9.946 0 01-9.516-13.83 9.95 9.95 0 019.516-6.052c4.801-.033 9.951 3.455 9.951 9.931zm-15.645-1.982h11.453c-.834-2.773-3.37-4.041-5.746-4.041a6.062 6.062 0 00-5.714 4.025l.007.016zM71.333 16.615a1.994 1.994 0 10-2.819 2.819l15.949 15.949a1.994 1.994 0 102.818-2.819L71.333 16.615z"
					fill="#fff"
				/>
				<path
					d="M87.22 19.494a1.994 1.994 0 10-2.818-2.819L68.575 32.502a1.994 1.994 0 102.82 2.818L87.22 19.494zM61.736 12.716a2.014 2.014 0 01-.597-1.389 1.956 1.956 0 013.37-1.388c.36.372.56.87.558 1.388a1.975 1.975 0 01-.558 1.389 2.004 2.004 0 01-2.773 0zM91.267 12.716a2.024 2.024 0 01-.593-1.389 1.955 1.955 0 013.37-1.388c.358.373.557.87.555 1.388a1.988 1.988 0 01-.555 1.389 2.008 2.008 0 01-2.777 0z"
					fill="#fff"
				/>
			</svg>
		}
	/>
);

export default SvgPixieSolidDark;
