import React, { memo, PropsWithChildren } from 'react';
import { Body2 } from '@pixiepkg/react';
import { FacebookSolid } from '@pixiepkg/icon';
import s from '@containers/LoginModal/styles/button.module.scss';
import ReactFacebookLogin, {
	ReactFacebookFailureResponse,
	ReactFacebookLoginInfo
} from 'react-facebook-login/dist/facebook-login-render-props';

interface FacebookButtonProps {
	callback: (userInfo: ReactFacebookLoginInfo | ReactFacebookFailureResponse) => void;
}

export const FacebookLoginButton: React.FC<PropsWithChildren<FacebookButtonProps>> = memo(
	(props) => {
		return process.env.NEXT_PUBLIC_FACEBOOK_APP_ID ? (
			<ReactFacebookLogin
				appId={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}
				callback={props.callback}
				disableMobileRedirect={true}
				render={(renderProps: any) => (
					<button
						disabled={
							renderProps.isDisabled ||
							renderProps.isProcessing ||
							!renderProps.isSdkLoaded
						}
						onClick={renderProps.onClick}
						className={s.button}
					>
						<FacebookSolid size={24} />
						<Body2 medium className={s.text}>
							Continue with Facebook
						</Body2>
					</button>
				)}
			/>
		) : (
			<button disabled className={s.button}>
				<FacebookSolid size={24} />
				<Body2 medium className={s.text}>
					Continue with Facebook
				</Body2>
			</button>
		);
	}
);

FacebookLoginButton.displayName = 'FacebookLoginButton';
