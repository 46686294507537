// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgBriefcaseColor = (props: IconProps) => (
	<Icon
		title={'SvgBriefcaseColor'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<path
					d="M21.09 6.98c-.85-.94-2.27-1.41-4.33-1.41h-.24v-.04c0-1.68 0-3.76-3.76-3.76h-1.52c-3.76 0-3.76 2.09-3.76 3.76v.05h-.24c-2.07 0-3.48.47-4.33 1.41-.99 1.1-.96 2.58-.86 3.59l.01.07.077.813c.015.15.095.285.221.368.24.157.641.415.882.549.14.09.29.17.44.25 1.71.94 3.59 1.57 5.5 1.88.09.94.5 2.04 2.69 2.04s2.62-1.09 2.69-2.06c2.04-.33 4.01-1.04 5.79-2.08.06-.03.1-.06.15-.09.397-.224.808-.5 1.183-.771a.493.493 0 00.201-.346l.016-.143.05-.47c.01-.06.01-.11.02-.18.08-1.01.06-2.39-.88-3.43zm-8 6.85c0 1.06 0 1.22-1.23 1.22s-1.23-.19-1.23-1.21v-1.26h2.46v1.25zM8.91 5.57v-.04c0-1.7 0-2.33 2.33-2.33h1.52c2.33 0 2.33.64 2.33 2.33v.05H8.91v-.01z"
					fill="#13C2C2"
				/>
				<path
					d="M20.873 13.734a.509.509 0 01.726.502l-.36 3.954c-.21 2-1.03 4.04-5.43 4.04H8.19c-4.4 0-5.22-2.04-5.43-4.03l-.34-3.748a.508.508 0 01.716-.506c1.14.516 3.242 1.43 4.541 1.77a.57.57 0 01.37.315c.607 1.298 1.923 1.989 3.824 1.989 1.882 0 3.215-.717 3.824-2.019a.571.571 0 01.37-.314c1.379-.363 3.618-1.386 4.81-1.953z"
					fill="#13C2C2"
				/>
			</svg>
		}
	/>
);

export default SvgBriefcaseColor;
