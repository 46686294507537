// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgWalletOutline = (props: IconProps) => (
	<Icon
		title={'SvgWalletOutline'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 18 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<path
					d="M17.334 7.142v1.716a.856.856 0 01-.834.85h-1.633c-.9 0-1.725-.658-1.8-1.558-.05-.525.15-1.017.5-1.358a1.66 1.66 0 011.2-.5H16.5a.856.856 0 01.834.85z"
					fill="#A0AEC0"
				/>
				<path
					d="M16.059 10.958h-1.192c-1.583 0-2.917-1.191-3.05-2.708a2.932 2.932 0 01.875-2.35 2.869 2.869 0 012.075-.858h1.292c.241 0 .441-.2.416-.442-.183-2.025-1.525-3.408-3.516-3.642a3.811 3.811 0 00-.625-.041h-7.5c-.234 0-.459.016-.675.05C2.034 1.233.667 2.817.667 5.083v5.834c0 2.3 1.867 4.166 4.167 4.166h7.5c2.333 0 3.941-1.458 4.141-3.683a.409.409 0 00-.416-.442zM9.834 6.125h-5a.63.63 0 01-.625-.625.63.63 0 01.625-.625h5a.63.63 0 01.625.625.63.63 0 01-.625.625z"
					fill="#A0AEC0"
				/>
			</svg>
		}
	/>
);

export default SvgWalletOutline;
