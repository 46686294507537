// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgEducateColor = (props: IconProps) => (
	<Icon
		title={'SvgEducateColor'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<path
					d="M17.145 15.637c.712-.44 1.647.04 1.647.84v1.29c0 1.269-1.052 2.629-2.317 3.029l-3.391 1.06c-.595.19-1.563.19-2.147 0l-3.391-1.06c-1.276-.4-2.317-1.76-2.317-3.03v-1.3c0-.79.935-1.27 1.636-.84l2.19 1.34c.84.53 1.903.79 2.966.79 1.063 0 2.126-.26 2.966-.79l2.158-1.33z"
					fill="#2494F9"
				/>
				<path
					d="M20.493 6.46l-6.367-3.93c-1.148-.71-3.04-.71-4.188 0l-6.4 3.93c-2.05 1.249-2.05 4.078 0 5.338l1.701 1.04 4.699 2.879c1.148.71 3.04.71 4.188 0l4.666-2.88 1.457-.9v3.06c0 .41.361.75.797.75.436 0 .797-.34.797-.75v-4.919c.425-1.29-.01-2.79-1.35-3.619z"
					fill="#2494F9"
				/>
			</svg>
		}
	/>
);

export default SvgEducateColor;
