// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgGroupPeopleOutline = (props: IconProps) => (
	<Icon
		title={'SvgGroupPeopleOutline'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<path
					d="M17.602 7.498a.566.566 0 00-.177 0 2.395 2.395 0 01-2.31-2.399c0-1.33 1.07-2.4 2.403-2.4a2.401 2.401 0 012.403 2.4 2.41 2.41 0 01-2.32 2.4zM16.642 14.268c1.276.214 2.683-.01 3.67-.67 1.314-.874 1.314-2.306 0-3.18-.996-.66-2.422-.883-3.698-.66M6.396 7.498a.565.565 0 01.177 0A2.395 2.395 0 008.883 5.1c0-1.33-1.071-2.4-2.403-2.4a2.401 2.401 0 00-2.404 2.4 2.41 2.41 0 002.32 2.4zM7.355 14.268c-1.276.214-2.683-.01-3.67-.67-1.313-.874-1.313-2.306 0-3.18.997-.66 2.422-.883 3.698-.66M12.013 14.444a.565.565 0 00-.177 0 2.395 2.395 0 01-2.31-2.399c0-1.33 1.071-2.399 2.403-2.399a2.401 2.401 0 012.403 2.4c-.009 1.301-1.034 2.361-2.32 2.398zM9.302 17.374c-1.313.874-1.313 2.306 0 3.18 1.49.995 3.93.995 5.421 0 1.314-.874 1.314-2.306 0-3.18-1.48-.986-3.93-.986-5.421 0z"
					stroke="#2D3748"
					strokeWidth={1.2}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		}
	/>
);

export default SvgGroupPeopleOutline;
