// This component was generated by SVRG.
// Please DO NOT EDIT this file directly, use `yarn build:icon` instead.
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Icon, IconProps } from '@components/Icon';
const SvgGlobeOutlined = (props: IconProps) => (
	<Icon
		title={'SvgGlobeOutlined'}
		{...props}
		children={
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 18 18"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<path
					d="M9 16.5a7.5 7.5 0 100-15 7.5 7.5 0 000 15z"
					stroke="#2D3748"
					strokeWidth={1.1}
					strokeMiterlimit={10}
				/>
				<path
					d="M1.5 9h15"
					stroke="#2D3748"
					strokeWidth={1.1}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M9 16.299c1.726 0 3.125-3.268 3.125-7.299 0-4.03-1.4-7.299-3.125-7.299-1.726 0-3.125 3.268-3.125 7.3 0 4.03 1.4 7.298 3.125 7.298z"
					stroke="#2D3748"
					strokeWidth={1.3}
					strokeMiterlimit={10}
				/>
			</svg>
		}
	/>
);

export default SvgGlobeOutlined;
